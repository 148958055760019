import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faRankingStar,
    faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";

import { RootState } from "../../store";
import useApi from "../../hooks/useApi";
import useQuery from "../../hooks/useQuery";
import { LiveScore, LiveScoreTeams } from "../../models/LiveScores";
import { Show } from "../../models/Shows";
import apiRoutes from "../../services/apiRoutes";
import echo from "../../config/echo";
import determineCountdown from "../../helpers/determineCountdown";

import { Well } from "../atoms";
// import Ad from "../atoms/Ad";
import CurrentShowLeagues from "../molecules/CurrentShowLeagues";
import LeagueButtons from "../molecules/LeagueButtons";
import ScoringBreakdown from "../molecules/ScoringBreakdown";
import ShowLinkList from "../organisms/ShowLinkList";
import ScoresTemplate from "../templates/ScoresTemplate";
import GoogleAd from "../atoms/GoogleAd";
import LiveScoresList from "../organisms/LiveScoresList";
import CountdownTimer from "../molecules/CountdownTimer";
import TopScorersModal from "../molecules/TopScorersModal";
import { Row } from "react-bootstrap";
import LiveTeamModal from "../molecules/LiveTeamModal";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";

interface ParamTypes {
    slug: string;
    episode: string;
}

const LiveScores: React.FC = () => {
    const query = useQuery();
    const subscription = useSelector((state: RootState) => state.subscription);
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );
    const [leagueId, setLeagueId] = useState(
        query.get("league_id") ? query.get("league_id") : "default"
    );
    const [show, setShow] = useState<Show>();
    const [showType, setShowType] = useState("Episode");
    const [loading, setLoading] = useState(true);
    const [liveScores, setLiveScores] = useState<LiveScore[]>([]);
    const [liveScoreTeams, setLiveScoreTeams] = useState<LiveScoreTeams>([]);
    const [showTopScorersModal, setShowTopScorersModal] =
        useState<boolean>(false);
    const [showLiveTeamModal, setShowLiveTeamModal] = useState<boolean>(false);
    const { slug, episode } = useParams<ParamTypes>();

    const getShow = useApi(apiRoutes.GET_SHOW_BY_SLUG(slug), {
        onSuccess: onShowSuccess,
        responseKey: "show",
    });
    const getLiveScores = useApi(apiRoutes.GET_LIVE_SCORES(slug, episode), {
        responseKey: "scoring",
        onSuccess,
    });
    const getLiveScore = useApi(apiRoutes.GET_LIVE_SCORE(), {
        responseKey: "liveScore",
        onSuccess(response: LiveScore) {
            setLiveScores((current) => [response, ...current]);
        },
    });
    const getLiveTeams = useApi(apiRoutes.GET_LIVE_TEAM(slug, episode), {
        responseKey: "leagueTeams",
        onSuccess: (response: LiveScoreTeams) => {
            setLiveScoreTeams(response);
        },
    });

    useEffect(() => {
        getShow.request({ live: true });
        getLiveTeams.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!subscription) {
            window.location.href = "/";
        } else {
            if (!hasPremiumSub(subscription.name)) {
                window.location.href = "/";
            }
        }
    }, [subscription]);

    useEffect(() => {
        getLiveScores.request({
            league_id: leagueId === "default" ? 0 : leagueId,
        });

        const liveScoreChannel = echo.channel(`liveScore.${slug}`);
        const liveScoreDeleteChannel = echo.channel(`liveScoreDelete`);

        //register socket events
        liveScoreChannel.stopListening(".LiveScoreCreated");
        liveScoreDeleteChannel.stopListening(".LiveScoreDeleted");
        liveScoreChannel.listen(".LiveScoreCreated", (data: LiveScore) => {
            getLiveScore.request({
                id: data.id,
                league_id: leagueId === "default" ? 0 : leagueId,
            });
        });
        liveScoreDeleteChannel.listen(".LiveScoreDeleted", (id: string) => {
            getLiveScores.request({
                league_id: leagueId === "default" ? 0 : leagueId,
            });
        });

        return () => {
            liveScoreChannel.stopListening(".LiveScoreCreated");
            liveScoreDeleteChannel.stopListening(".LiveScoreDeleted");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leagueId]);

    function onSuccess(response: LiveScore[]) {
        setLiveScores(response);
        setLoading(false);
    }

    function onShowSuccess(response: Show) {
        setShow(response);
        setShowType(response.type === "week" ? "Week" : "Episode");
    }

    const onLeagueIdChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLeagueId(event.target.value);
    };

    return (
        <>
            <ScoresTemplate
                show={show && show}
                loading={loading}
                header={
                    show && showType
                        ? `${show.show}: ${showType} ${episode} Live Scores`
                        : ""
                }
                skeleton={true}
                sideCards={
                    show && (
                        <>
                            {show.active && (
                                <Well className="d-none d-lg-block d-xl-block">
                                    {/* <LeagueDifference /> */}
                                    <LeagueButtons
                                        showId={show.id}
                                        slug={show.slug}
                                    />
                                    <CurrentShowLeagues showId={show.id} />
                                </Well>
                            )}
                            <div className="d-none d-lg-block d-xl-block">
                                {/* <Ad /> */}
                                <GoogleAd slot="2424423133" height={280} />
                            </div>
                            <ShowLinkList
                                type="articles"
                                show={show}
                                viewAll={`/shows/article/${show.slug}`}
                            />
                            <ShowLinkList type="scores" show={show} />
                        </>
                    )
                }
            >
                <>
                    {show && show.current_episode <= parseInt(episode) && (
                        <CountdownTimer
                            live
                            targetDate={determineCountdown(
                                timezoneOffsets,
                                show,
                                true
                            )}
                            episode={parseInt(episode)}
                        />
                    )}
                    {show && (
                        <ScoringBreakdown
                            leagueId={leagueId}
                            showId={show.id}
                            onChange={onLeagueIdChange}
                        />
                    )}
                    {show && leagueId !== "default" && (
                        <Row
                            style={{ cursor: "pointer" }}
                            className="align-items-center justify-content-center mb-4"
                            onClick={() =>
                                setShowLiveTeamModal(!showLiveTeamModal)
                            }
                        >
                            <FontAwesomeIcon
                                fontSize={20}
                                icon={faPeopleGroup}
                                className="mr-2"
                            />
                            View Team
                        </Row>
                    )}
                    {show && (
                        <Row
                            style={{ cursor: "pointer" }}
                            className="align-items-center justify-content-center mb-4"
                            onClick={() =>
                                setShowTopScorersModal(!showTopScorersModal)
                            }
                        >
                            <FontAwesomeIcon
                                fontSize={20}
                                icon={faRankingStar}
                                className="mr-2"
                            />
                            Top Scorers
                        </Row>
                    )}
                    <h4 className="text-center">Live Scores:</h4>
                    <div className="d-sm-block d-xs-block d-md-block d-lg-none d-xl-none">
                        {/* <Ad /> */}
                        <GoogleAd slot="2424423133" height={90} />
                    </div>
                    <LiveScoresList
                        liveScoreTeams={liveScoreTeams}
                        leagueId={leagueId}
                        show={show}
                        liveScores={liveScores}
                    />
                </>
            </ScoresTemplate>
            {show && (
                <TopScorersModal
                    leagueId={leagueId}
                    episode={episode}
                    liveScores={liveScores}
                    show={show}
                    showModal={showTopScorersModal}
                    setShowModal={setShowTopScorersModal}
                />
            )}
            {show && (
                <LiveTeamModal
                    leagueId={leagueId}
                    episode={episode}
                    liveScores={liveScores}
                    show={show}
                    showModal={showLiveTeamModal}
                    setShowModal={setShowLiveTeamModal}
                />
            )}
        </>
    );
};

export default LiveScores;
