import React, { ChangeEvent } from "react";

import Form from "react-bootstrap/Form";

import { Show } from "../../models/Shows";

import { FormGroupWrapper } from ".";

interface LogScoresEpisodeSelectProps {
    show: Show;
    leagueId?: string;
    episode: number | string;
    customLeague?: boolean;
    live?: boolean;
}

const LogScoresEpisodeSelect: React.FC<LogScoresEpisodeSelectProps> = ({
    show,
    episode,
    leagueId,
    customLeague,
    live,
}) => {
    return (
        <FormGroupWrapper>
            <Form.Control
                as="select"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (leagueId) {
                        if (customLeague) {
                            window.location.href = `/myleagues/logscores/${show.id}/${leagueId}/${event.target.value}`;
                        } else {
                            window.location.href = `/custom/scoring/log/${leagueId}/${event.target.value}`;
                        }
                    } else {
                        if (live) {
                            window.location.href = `/admin/liveScores/${show.id}/${event.target.value}`;
                        } else {
                            window.location.href = `/admin/logscores/${show.id}/${event.target.value}`;
                        }
                    }
                }}
                value={episode}
            >
                <option value="0">
                    {show.type === "week" ? "Choose Week" : "Choose Episode"}
                </option>
                {show &&
                    Array.from(
                        Array(show.episodes + 1).keys(),
                        (episode: number) => {
                            if (episode > 0) {
                                return (
                                    <option key={episode} value={episode}>
                                        {show.type === "week"
                                            ? `Week ${episode}${
                                                  episode ===
                                                  show.current_episode
                                                      ? " (current)"
                                                      : ""
                                              }`
                                            : `Episode ${episode}${
                                                  episode ===
                                                  show.current_episode
                                                      ? " (current)"
                                                      : ""
                                              }`}
                                    </option>
                                );
                            } else {
                                return null;
                            }
                        }
                    )}
            </Form.Control>
        </FormGroupWrapper>
    );
};

export default LogScoresEpisodeSelect;
