import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { imageUrl } from "../../config";
import useApi from "../../hooks/useApi";
import { League } from "../../models/Leagues";
import {
    LeagueUser,
    Team as TeamModel,
    TeamResponse,
    WeeklyConfidenceTeam,
} from "../../models/LeagueUsers";
import { Show } from "../../models/Shows";
import apiRoutes from "../../services/apiRoutes";
import { determineTimezone } from "../../helpers/timezones";
import { RootState } from "../../store";
import { Starters } from "../../models/TeamLeagueTeams";
import { QuestionAnswerResponse } from "../../models/QuestionAnswers";
import { Question } from "../../models/Questions";

import { Tab, Tabs } from "react-bootstrap";

import AreYouSureModal from "../atoms/AreYouSureModal";
import AddPlayerModal from "../molecules/AddPlayerModal";
import FullDraftTeamTable from "../molecules/FullDraftTeamTable";
import CurrentTeam from "../molecules/CurrentTeam";
import WeeklyTeamItem from "../molecules/WeeklyTeamItem";
import TeamHeader from "../molecules/TeamHeader";
import TeamTemplate from "../templates/TeamTemplate";
import ChallengeQuestionList from "../organisms/ChallengeQuestionList";
import { AccordionStyled, TabsWrapper, Well } from "../atoms";
import WeeklyChallengeQuestionList from "../organisms/WeeklyChallengeQuestionList";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import WeeklyConfidenceTeamItem from "../molecules/WeeklyConfidenceTeamItem";

interface ParamTypes {
    id: string;
}

const Team: React.FC = () => {
    const { id } = useParams<ParamTypes>();
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );
    const [categories, setCategories] = useState<string[]>([]);
    const [league, setLeague] = useState<League>();
    const [leagueUser, setLeagueUser] = useState<LeagueUser>();
    const [team, setTeam] = useState<any>(); //TeamModel[] | WeeklyTeamResponse not sure why this doesn't work yet
    const [currentTeam, setCurrentTeam] = useState<TeamModel[]>();
    const [commissioner, setCommissioner] = useState<boolean>(false);
    const [show, setShow] = useState<Show>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showFinishDraftModal, setShowFinishDraftModal] =
        useState<boolean>(false);
    const [finishingDraft, setFinishingDraft] = useState<boolean>(false);
    const [selectedPlayer, setSelectedPlayer] = useState<TeamModel>();
    const [selectedEpisode, setSelectedEpisode] = useState<number>(0);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [showAddPlayer, setShowAddPlayer] = useState<boolean>(false);
    const [adding, setAdding] = useState<boolean>(false);
    const [starters, setStarters] = useState<Starters[]>([]);
    const [seasonQs, setSeasonQs] = useState<Question[]>([]);
    const [weeklyQs, setWeeklyQs] = useState<Question[]>([]);
    const [seasonQuestions, setSeasonQuestions] = useState<
        QuestionAnswerResponse[]
    >([]);
    const [weeklyQuestions, setWeeklyQuestions] = useState<
        QuestionAnswerResponse[]
    >([]);
    const [confidencePool, setConfidencePool] = useState<
        WeeklyConfidenceTeam[]
    >([]);

    const teamRequest = useApi(apiRoutes.GET_TEAM(id), {
        onSuccess: onTeamLoaded,
    });
    const deletePlayerRequest = useApi(apiRoutes.REMOVE_PLAYER_FROM_TEAM(id), {
        onSuccess: (response: string) => {
            setShowDeleteModal(false);
            setDeleting(false);
            teamRequest.request(determineTimezone(timezoneOffsets));
            toast.success(response);
        },
        onFailure: () => {
            setShowDeleteModal(false);
            setDeleting(false);
        },
        responseKey: "message",
    });
    const addPlayerRequest = useApi(apiRoutes.ADD_PLAYER_TO_TEAM(id), {
        onSuccess: (response: string) => {
            setShowAddPlayer(false);
            setAdding(false);
            teamRequest.request(determineTimezone(timezoneOffsets));
            toast.success(response);
        },
        onFailure: () => {
            setShowAddPlayer(false);
            setAdding(false);
        },
        responseKey: "message",
    });
    const finishDraftRequest = useApi(
        apiRoutes.FINISH_DRAFT(league ? league.id : 0),
        {
            onSuccess: (response: string) => {
                setFinishingDraft(false);
                setShowFinishDraftModal(false);
                const updatedLeague = { ...league };
                setLeague({ ...updatedLeague, drafted: true });
                toast.success(response);
            },
            onFailure: () => {
                setFinishingDraft(false);
                setShowFinishDraftModal(false);
            },
            responseKey: "message",
        }
    );

    useEffect(() => {
        teamRequest.request(determineTimezone(timezoneOffsets));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onTeamLoaded(response: TeamResponse) {
        if (response.canViewTeam) {
            if (response.league.include_questions) {
                //filter weekly and season questions
                const seasonQs = response.questions.filter((q) => {
                    return q.type === "Season";
                });
                const weeklyQs = response.questions.filter((q) => {
                    return q.type === "Episode";
                });
                setSeasonQs(seasonQs);
                setWeeklyQs(weeklyQs);
                setSeasonQuestions(response.seasonQuestions);
                setWeeklyQuestions(response.weeklyQuestions);
            } else {
                setSeasonQuestions([]);
                setWeeklyQuestions([]);
                setWeeklyQs([]);
                setSeasonQs([]);
            }
            setWeeklyQuestions(response.weeklyQuestions);
            setSeasonQuestions(response.seasonQuestions);
            setCategories(response.categories);
            setLeague(response.league);
            setLeagueUser(response.leagueUser);
            setTeam(response.team);
            setCurrentTeam(response.currentTeam);
            setShow(response.show);
            setCommissioner(response.user ? response.user.commissioner : false);
            setStarters(response.starters);
            if (
                response.league.draft_type === "Confidence Pool" ||
                response.league.draft_type === "Eliminated Confidence Pool"
            ) {
                setConfidencePool(response.confidencePool);
            }
        } else {
            window.location.href = "/";
        }
    }

    const deletePlayer = () => {
        setDeleting(true);
        if (selectedEpisode > 0 && league.draft_type !== "Full Draft") {
            deletePlayerRequest.request({
                cast_id: selectedPlayer.cast_id,
                episode: selectedEpisode,
            });
        } else {
            deletePlayerRequest.request({ cast_id: selectedPlayer.cast_id });
        }
    };

    const addPlayer = (playerId: number) => {
        setAdding(true);
        if (selectedEpisode > 0 && league.draft_type !== "Full Draft") {
            addPlayerRequest.request({
                cast_id: playerId,
                episode: selectedEpisode,
            });
        } else {
            addPlayerRequest.request({ cast_id: playerId });
        }
    };

    const finishDraft = () => {
        setFinishingDraft(true);
        finishDraftRequest.request();
    };

    const calculateSeasonQTotal = (): number => {
        let total = 0;

        seasonQs.forEach((q) => {
            const userAnswers = seasonQuestions.filter((sq) => {
                return sq.question_id === q.id && sq.match > 0;
            });
            total += userAnswers.length * q.score;
        });

        return total;
    };

    return (
        <TeamTemplate
            header={league && league.name}
            league={league}
            headerSecondary={leagueUser && leagueUser.team_name}
            skeleton
            loading={teamRequest.loading}
            showId={show ? show.id : 0}
            image={show ? `${imageUrl}${show.img.replace(/ /g, "%20")}` : ""}
        >
            {!teamRequest.loading && league && categories && team && show && (
                <TabsWrapper>
                    <Tabs
                        style={
                            league.include_questions ? {} : { display: "none" }
                        }
                        defaultActiveKey="lineup"
                        className="col-md-12 pl-0 pr-0"
                    >
                        <Tab
                            tabClassName="col-6 text-center"
                            eventKey="lineup"
                            title="Lineup"
                        >
                            <Well>
                                <TeamHeader
                                    league={league}
                                    commissioner={commissioner}
                                    setShowAddPlayer={setShowAddPlayer}
                                    setShowFinishDraftModal={
                                        setShowFinishDraftModal
                                    }
                                    setSelectedEpisode={setSelectedEpisode}
                                    currentTeam={currentTeam}
                                    leagueUser={leagueUser}
                                />
                                <div className="mt-3">
                                    {league.draft_type === "Full Draft" && (
                                        <FullDraftTeamTable
                                            show={show}
                                            league={league}
                                            team={team}
                                            categories={categories}
                                            commissioner={commissioner}
                                            setSelectedPlayer={
                                                setSelectedPlayer
                                            }
                                            setShowDeleteModal={
                                                setShowDeleteModal
                                            }
                                        />
                                    )}
                                    {(league.draft_type === "Team" ||
                                        league.draft_type ===
                                            "Weekly Redraft" ||
                                        league.draft_type ===
                                            "Weekly Budget") && (
                                        <>
                                            {league.draft_type === "Team" && (
                                                <CurrentTeam
                                                    show={show}
                                                    league={league}
                                                    leagueUser={leagueUser}
                                                    currentTeam={currentTeam}
                                                    categories={categories}
                                                    commissioner={commissioner}
                                                    setSelectedPlayer={
                                                        setSelectedPlayer
                                                    }
                                                    setShowDeleteModal={
                                                        setShowDeleteModal
                                                    }
                                                    setSelectedEpisode={
                                                        setSelectedEpisode
                                                    }
                                                />
                                            )}
                                            <WeeklyTeamItem
                                                league={league}
                                                leagueUser={leagueUser}
                                                show={show}
                                                starters={starters}
                                                categories={categories}
                                                team={team}
                                                setShowAddPlayer={
                                                    setShowAddPlayer
                                                }
                                                setShowDeleteModal={
                                                    setShowDeleteModal
                                                }
                                                setSelectedEpisode={
                                                    setSelectedEpisode
                                                }
                                                setSelectedPlayer={
                                                    setSelectedPlayer
                                                }
                                                commissioner={commissioner}
                                            />
                                        </>
                                    )}
                                    {(league.draft_type === "Confidence Pool" ||
                                        league.draft_type ===
                                            "Eliminated Confidence Pool") && (
                                        <WeeklyConfidenceTeamItem
                                            confidencePool={confidencePool}
                                            leagueUser={leagueUser}
                                            show={show}
                                            eliminatedOnly={
                                                league.draft_type ===
                                                "Eliminated Confidence Pool"
                                            }
                                        />
                                    )}
                                </div>
                                <AreYouSureModal
                                    showModal={showDeleteModal}
                                    setShowModal={setShowDeleteModal}
                                    body={`Are you sure you want to remove <b>${
                                        selectedPlayer && selectedPlayer.name
                                    }</b> from <b>${leagueUser.team_name}</b>${
                                        selectedEpisode > 0
                                            ? ` in <b>Episode ${selectedEpisode}</b>`
                                            : ``
                                    }?`}
                                    onSuccess={() => deletePlayer()}
                                    submitting={deleting}
                                    submitText={"Removing"}
                                />
                                <AreYouSureModal
                                    showModal={showFinishDraftModal}
                                    setShowModal={setShowFinishDraftModal}
                                    body={`Are you sure you want to finish the draft?`}
                                    onSuccess={() => finishDraft()}
                                    submitting={finishingDraft}
                                    submitText={"Submitting"}
                                />
                                <AddPlayerModal
                                    teamId={leagueUser.id}
                                    episode={selectedEpisode}
                                    league={league}
                                    visible={showAddPlayer}
                                    setVisible={setShowAddPlayer}
                                    submitting={adding}
                                    onSubmit={(playerId: number) =>
                                        addPlayer(playerId)
                                    }
                                />
                            </Well>
                        </Tab>
                        <Tab
                            tabClassName="col-6 text-center"
                            eventKey="questions"
                            title="Challenge Questions"
                        >
                            <Well>
                                {seasonQs.length > 0 && (
                                    <AccordionStyled defaultExpanded>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <h4 className="mb-0 d-flex justify-content-center align-items-center">
                                                Season Questions
                                            </h4>
                                            <div
                                                style={{
                                                    flex: 1,
                                                    fontSize: 18,
                                                }}
                                                className="text-right pr-3"
                                            >
                                                {calculateSeasonQTotal()}
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ChallengeQuestionList
                                                questions={seasonQs}
                                                responses={seasonQuestions}
                                                episode={0}
                                                leagueUser={leagueUser}
                                                show={show}
                                                league={league}
                                            />
                                        </AccordionDetails>
                                    </AccordionStyled>
                                )}
                                {weeklyQs.length > 0 && (
                                    <WeeklyChallengeQuestionList
                                        questions={weeklyQs}
                                        responses={weeklyQuestions}
                                        show={show}
                                        league={league}
                                        leagueUser={leagueUser}
                                    />
                                )}
                            </Well>
                        </Tab>
                    </Tabs>
                </TabsWrapper>
            )}
        </TeamTemplate>
    );
};

export default Team;
