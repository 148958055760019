import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { IoMdPersonAdd } from "react-icons/io";

import { RootState } from "../../store";
import { League } from "../../models/Leagues";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { Show } from "../../models/Shows";

import LeagueAlerts from "../atoms/LeagueAlerts";
import AlertModal from "../atoms/AlertModal";
import LoginModal from "../molecules/LoginModal";
import LeagueActionButtons from "./LeagueActionButtons";
import LeagueRank from "../molecules/LeagueRank";
import { Socials } from "../../models/Socials";
import SocialLinks from "../atoms/SocialLinks";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import LeagueActionItem from "../atoms/LeagueActionItem";

interface LeagueActionsProps {
    league: League;
    socials?: Socials;
    leagueUser: LeaguesLeagueUser;
    show: Show;
    winner: string;
    tradeCount: number;
    teamLeagueSetUp: boolean;
    showSelectTeam: boolean;
    reloadLeague: () => void;
    commissionerNote: string;
}

const LeagueActions: React.FC<LeagueActionsProps> = ({
    league,
    leagueUser,
    show,
    socials,
    winner,
    tradeCount,
    showSelectTeam,
    teamLeagueSetUp,
    reloadLeague,
    commissionerNote,
}) => {
    const history = useHistory();
    const user = useSelector((state: RootState) => state.user);
    const subscription = useSelector((state: RootState) => state.subscription);
    const currentDay = new Date().getDay();
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);

    return (
        <>
            <LeagueAlerts
                league={league}
                winner={winner}
                commissionerNote={commissionerNote}
            />
            <Col>
                <Row className="ml-0 mr-0 mb-4 justify-content-center">
                    {!user.isLoggedIn && (
                        <LeagueActionItem
                            icon={<IoMdPersonAdd size={20} />}
                            label="Join League"
                            onPress={() => setShowLogin(true)}
                        />
                    )}
                    {user.isLoggedIn && !leagueUser && (
                        <LeagueActionItem
                            icon={<IoMdPersonAdd size={20} />}
                            label="Join League"
                            onPress={() =>
                                history.push(
                                    league.id === "0" ||
                                        league.id === 0 ||
                                        league.id === "team" ||
                                        league.id === "budget"
                                        ? `/myleagues/join/${league.id}/${league.show_id}`
                                        : `/myleagues/join/${league.id}`
                                )
                            }
                        />
                    )}
                    {user.isLoggedIn &&
                        leagueUser &&
                        league.active &&
                        show &&
                        leagueUser.id && (
                            <LeagueActionButtons
                                league={league}
                                leagueUser={leagueUser}
                                show={show}
                                tradeCount={tradeCount}
                                teamLeagueSetUp={teamLeagueSetUp}
                                showSelectTeam={showSelectTeam}
                                reloadLeague={reloadLeague}
                            />
                        )}
                </Row>
                {league.sponsored && socials && (
                    <>
                        {(socials.instagram ||
                            socials.facebook ||
                            socials.podcast ||
                            socials.tiktok ||
                            socials.twitter) && (
                            <div className="mb-4 text-center font-weight-bold">
                                <div className="mb-2">League Socials:</div>
                                <Row className="justify-content-center">
                                    <SocialLinks
                                        socials={socials}
                                        size="medium"
                                    />
                                </Row>
                            </div>
                        )}
                    </>
                )}
                {leagueUser && leagueUser.id && (
                    <LeagueRank leagueUserId={leagueUser.id} />
                )}
                {!show.custom &&
                    show.active &&
                    show.live_scoring &&
                    league.draft_type !== "Confidence Pool" &&
                    league.draft_type !== "Eliminated Confidence Pool" &&
                    (show.current_episode <= show.episodes ||
                        (show.current_episode > show.episodes &&
                            show.show.includes("Big Brother"))) && (
                        <Button
                            onClick={() => {
                                if (
                                    subscription &&
                                    hasPremiumSub(subscription.name)
                                ) {
                                    if (
                                        show.show.includes("Big Brother") &&
                                        show.current_episode !== 1 &&
                                        (currentDay === 1 ||
                                            currentDay === 2 ||
                                            (currentDay === 3 &&
                                                show.current_episode !== 1) ||
                                            currentDay === 4)
                                    ) {
                                        window.location.href = `/shows/liveScores/${
                                            show.slug
                                        }/${
                                            show.current_episode - 1
                                        }?league_id=${league.id}`;
                                    } else {
                                        window.location.href = `/shows/liveScores/${show.slug}/${show.current_episode}?league_id=${league.id}`;
                                    }
                                } else {
                                    setShowAlert(true);
                                }
                            }}
                            variant="danger"
                            className="mt-2 w-100"
                        >
                            <RadioButtonCheckedIcon
                                className="mr-1 pulse"
                                fontSize="small"
                            />
                            Live Scoring
                        </Button>
                    )}
            </Col>
            <AlertModal
                showModal={showAlert}
                setShowModal={setShowAlert}
                title="Live Scoring"
                body={
                    <p>
                        Live scoring is only available for PREMIUM subscribed
                        users.{" "}
                        {user.free_trial_used === false
                            ? "You can try it out for free for 1 month"
                            : "If you would like access to live scoring, please subscribe"}{" "}
                        <a href="/subscribe">here</a>!
                    </p>
                }
            />
            <LoginModal
                show={showLogin}
                onHide={() => setShowLogin(false)}
                message={"You must login to join league"}
            />
        </>
    );
};

export default LeagueActions;
