import React, { useState } from "react";
import { useTheme } from "styled-components";

import { Avatar } from "@mui/material";
import { OverlayTrigger, Popover } from "react-bootstrap";

interface ProfileOverlayProps {
    img: string;
    showProfile: (show: boolean) => void;
}

const ProfileOverlay: React.FC<ProfileOverlayProps> = ({
    img,
    showProfile,
}) => {
    const theme = useTheme();
    const [show, setShow] = useState<boolean>(false);

    return (
        <OverlayTrigger
            placement="top"
            show={show}
            defaultShow={false}
            trigger={["focus", "hover", "click"]}
            overlay={
                <Popover
                    onMouseEnter={() => {
                        setShow(true);
                    }}
                    onMouseLeave={() => {
                        setShow(false);
                    }}
                    id="profile-hover"
                >
                    <Popover.Content
                        style={{
                            backgroundColor: theme.primary,
                            color: theme.textColor,
                        }}
                    >
                        <div
                            style={{
                                color: "#0495ee",
                                cursor: "pointer",
                                textDecoration: "underline",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                showProfile(true);
                            }}
                        >
                            View Profile
                        </div>
                    </Popover.Content>
                </Popover>
            }
        >
            <div
                style={{ width: 40, marginRight: 10 }}
                onMouseEnter={() => {
                    setShow(true);
                }}
                onMouseLeave={() => {
                    setShow(false);
                }}
            >
                <Avatar className="mr-3" src={img} />
            </div>
        </OverlayTrigger>
    );
};

export default ProfileOverlay;
