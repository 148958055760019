import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Formik } from "formik";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { Cast } from "../../models/Cast";
import { Team, TeamResponse } from "../../models/LeagueUsers";

import { ModalStyled } from "../atoms";
import { InputSkeleton } from "../atoms/Skeletons";
import Select from "../atoms/Select";
import AreYouSureModal from "../atoms/AreYouSureModal";

interface SwapPlayerModalProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    teamId: number;
    remaining: number;
}

const validationSchema = yup.object({
    removed: yup
        .number()
        .moreThan(0, "Please Select Player From Your Team")
        .required("Please Select Player From Your Team"),
    added: yup
        .number()
        .moreThan(0, "Please Select Available Player")
        .required("Please Select Available Player"),
});

const SwapPlayerModal: React.FC<SwapPlayerModalProps> = ({
    visible,
    setVisible,
    teamId,
    remaining,
}) => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [data, setData] = useState<{
        removed: { id: number; name: string };
        added: { id: number; name: string };
    }>({
        removed: {
            id: 0,
            name: "",
        },
        added: {
            id: 0,
            name: "",
        },
    });
    const [players, setPlayers] = useState<Cast[]>();
    const [team, setTeam] = useState<Team[]>();
    const [error, setError] = useState<string>("");

    const availableList = useApi(apiRoutes.GET_PLAYER_LIST(teamId.toString()), {
        onSuccess: (response: { list: Cast[]; error?: string }) => {
            if (response.error) {
                setError(response.error);
            } else {
                if (response.list.length > 0) {
                    setPlayers(response.list);
                    setError("");
                } else {
                    setError("No Cast Available");
                }
            }
        },
        onFailure: () => {
            toast.error("There was an issue grabbing the cast list");
        },
    });
    const teamRequest = useApi(apiRoutes.GET_TEAM(teamId.toString()), {
        onSuccess: onTeamLoaded,
    });
    const swapPlayerRequest = useApi(apiRoutes.SWAP_PLAYER(teamId.toString()), {
        onSuccess: (response: string) => {
            setSubmitting(false);
            setVisible(false);
            setShowModal(false);
            toast.success(response);
        },
        onFailure: () => {
            toast.error("There was an issue swapping player");
            setSubmitting(false);
            setShowModal(false);
        },
        responseKey: "message",
    });

    function onTeamLoaded(response: TeamResponse) {
        setTeam(response.currentTeam);
    }

    useEffect(() => {
        if (visible) {
            availableList.request({ swap: true });
            teamRequest.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const initialValues = {
        removed: 0,
        added: 0,
    };

    return (
        <>
            <ModalStyled
                show={visible}
                onHide={() => setVisible(false)}
                size="lg"
                className="text-center"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Swap Player</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            const removedPlayer = team.filter((t) => {
                                return (
                                    values.removed.toString() ===
                                    t.cast_id.toString()
                                );
                            })[0];
                            const addedPlayer = players.filter((p) => {
                                return (
                                    values.added.toString() === p.id.toString()
                                );
                            })[0];

                            setData({
                                removed: {
                                    id: values.removed,
                                    name: removedPlayer.name,
                                },
                                added: {
                                    id: values.added,
                                    name: addedPlayer.name,
                                },
                            });
                            setVisible(false);
                            setShowModal(true);
                        }}
                    >
                        {({ handleSubmit }) => (
                            <Form
                                onSubmit={(e) => {
                                    handleSubmit(e);
                                }}
                            >
                                {error.length > 0 && (
                                    <Alert variant="danger">{error}</Alert>
                                )}
                                {(teamRequest.loading ||
                                    availableList.loading) && <InputSkeleton />}
                                {!teamRequest.loading &&
                                    !availableList.loading &&
                                    players &&
                                    error.length === 0 && (
                                        <>
                                            <Select
                                                name="removed"
                                                defaultKey={0}
                                                defaultValue="Choose Player from Team to Swap"
                                                selectKey="cast_id"
                                                selectValue="name"
                                                items={team}
                                            />
                                            <SwapHorizIcon
                                                className="mb-3"
                                                fontSize="large"
                                            />
                                            <Select
                                                name="added"
                                                defaultKey={0}
                                                defaultValue="Choose Available Player to Swap"
                                                selectKey="id"
                                                selectValue="name"
                                                items={players}
                                            />
                                            <Button
                                                variant="success"
                                                disabled={
                                                    submitting ? true : false
                                                }
                                                onClick={() => {
                                                    handleSubmit();
                                                }}
                                            >
                                                Swap
                                            </Button>{" "}
                                        </>
                                    )}
                                <Button
                                    variant="light"
                                    onClick={() => setVisible(false)}
                                >
                                    Cancel
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </ModalStyled>
            <AreYouSureModal
                showModal={showModal}
                setShowModal={setShowModal}
                submitting={submitting}
                body={`Are you sure you want to swap <b>${
                    data.removed.name
                }</b> for <b>${
                    data.added.name
                }</b>? You will have <b>${remaining}</b> swap${
                    remaining === 1 ? "" : "s"
                } remaining.`}
                onSuccess={() => {
                    setSubmitting(true);
                    swapPlayerRequest.request({
                        removed: data.removed.id,
                        added: data.added.id,
                    });
                }}
                onCancel={() => setVisible(true)}
            />
        </>
    );
};

export default SwapPlayerModal;
