import React, { useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";

import SettingsIcon from "@mui/icons-material/Settings";
import { NavDropdown } from "react-bootstrap";

import { League } from "../../models/Leagues";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { Show } from "../../models/Shows";

import CommissionerNote from "../molecules/CommissionerNote";
import AreYouSureModal from "../atoms/AreYouSureModal";
import { CommissionerDropdown } from "../templates/styles/LeagueTemplate.styled";

interface CommissionerActionsProps {
    commissionerNote: string | null;
    setCommissionerNote: (value: string) => void;
    league: League;
    show: Show;
    reloadLeague: () => void;
}

const CommissionerActions: React.FC<CommissionerActionsProps> = ({
    league,
    show,
    commissionerNote,
    setCommissionerNote,
    reloadLeague,
}) => {
    const theme = useTheme();
    const history = useHistory();
    const [showCommissionerNote, setShowCommissionerNote] =
        useState<boolean>(false);
    const [showComplete, setShowComplete] = useState<boolean>(false);
    const [showRestore, setShowRestore] = useState<boolean>(false);
    const [showUnlock, setShowUnlock] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const completeLeagueRequest = useApi(
        apiRoutes.COMPLETE_LEAGUE(league.id.toString()),
        {
            onSuccess: (message: string) => {
                toast.success(message);
                setSubmitting(false);
                setShowComplete(false);
                reloadLeague();
            },
            onFailure: () => setSubmitting(false),
            responseKey: "message",
        }
    );
    const restoreLeagueRequest = useApi(
        apiRoutes.RESTORE_LEAGUE(league.id.toString()),
        {
            onSuccess: (message: string) => {
                toast.success(message);
                setSubmitting(false);
                setShowRestore(false);
                reloadLeague();
            },
            onFailure: () => setSubmitting(false),
            responseKey: "message",
        }
    );
    const unlockLeagueRequest = useApi(
        apiRoutes.UNLOCK_LEAGUE(league.id.toString()),
        {
            onSuccess: (message: string) => {
                toast.success(message);
                setSubmitting(false);
                setShowUnlock(false);
                reloadLeague();
            },
            onFailure: () => setSubmitting(false),
            responseKey: "message",
        }
    );

    return (
        <>
            <CommissionerDropdown
                alignRight={true}
                title={
                    <SettingsIcon
                        fontSize={"large"}
                        htmlColor={theme.textColor}
                    />
                }
                id="basic-nav-dropdown nav-image"
            >
                {league.active &&
                    league.draft_type !== "Confidence Pool" &&
                    league.draft_type !== "Eliminated Confidence Pool" && (
                        <>
                            <NavDropdown.Item
                                onClick={() => setShowCommissionerNote(true)}
                            >
                                {commissionerNote && commissionerNote.length > 0
                                    ? "Edit"
                                    : "Add"}{" "}
                                Commissioner Note
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() =>
                                    history.push(
                                        `/custom/scoring/add/${league.id}`
                                    )
                                }
                            >
                                Add Custom Scoring
                            </NavDropdown.Item>
                        </>
                    )}
                {league.custom_scores > 0 && league.active && (
                    <NavDropdown.Item
                        onClick={() =>
                            history.push(`/custom/scoring/edit/${league.id}`)
                        }
                    >
                        Edit Custom Scoring
                    </NavDropdown.Item>
                )}
                {league.custom && league.active && (
                    <NavDropdown.Item
                        onClick={() =>
                            history.push(
                                `/myleagues/logscores/${league.show_id}/${league.id}`
                            )
                        }
                    >
                        Log Scores
                    </NavDropdown.Item>
                )}
                {league.custom_scores > 0 && league.active && (
                    <NavDropdown.Item
                        onClick={() =>
                            history.push(`/custom/scoring/log/${league.id}`)
                        }
                    >
                        Log Custom Scoring
                    </NavDropdown.Item>
                )}
                {league.active && (
                    <NavDropdown.Item
                        onClick={() => {
                            if (league.custom) {
                                history.push(
                                    `/myleagues/edit/custom/league/${league.id}`
                                );
                            } else {
                                history.push(
                                    `/myleagues/edit/league/${league.id}`
                                );
                            }
                        }}
                    >
                        Edit League
                    </NavDropdown.Item>
                )}
                {league.custom && league.active && show.locked && (
                    <NavDropdown.Item onClick={() => setShowUnlock(true)}>
                        Unlock League
                    </NavDropdown.Item>
                )}
                {league.custom && league.active && (
                    <NavDropdown.Item onClick={() => setShowComplete(true)}>
                        Complete League
                    </NavDropdown.Item>
                )}
                {league.custom && !league.active && (
                    <NavDropdown.Item onClick={() => setShowRestore(true)}>
                        Restore League
                    </NavDropdown.Item>
                )}
            </CommissionerDropdown>
            <AreYouSureModal
                showModal={showComplete}
                setShowModal={setShowComplete}
                body="Are you sure you want to complete this league?"
                onSuccess={() => {
                    setSubmitting(true);
                    completeLeagueRequest.request();
                }}
                submitting={submitting}
            />
            <AreYouSureModal
                showModal={showRestore}
                setShowModal={setShowRestore}
                body="Are you sure you want to restore this league?"
                onSuccess={() => {
                    setSubmitting(true);
                    restoreLeagueRequest.request();
                }}
                submitting={submitting}
            />
            <AreYouSureModal
                showModal={showUnlock}
                setShowModal={setShowUnlock}
                body="Are you sure you want to unlock this league?"
                onSuccess={() => {
                    setSubmitting(true);
                    unlockLeagueRequest.request();
                }}
                submitting={submitting}
            />
            <CommissionerNote
                leagueId={league.id}
                note={commissionerNote}
                setShowModal={setShowCommissionerNote}
                showModal={showCommissionerNote}
                setCommissionerNote={setCommissionerNote}
            />
        </>
    );
};

export default CommissionerActions;
