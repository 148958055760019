import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

import Button from "react-bootstrap/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { Cast } from "../../models/Cast";
import { League } from "../../models/Leagues";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { Show } from "../../models/Shows";

import StarterEmpty from "../atoms/StarterEmpty";
import WeeklyStarter from "../molecules/WeeklyStarter";
import SelectTeamListItem from "../molecules/SelectTeamListItem";
import {
    Body,
    Footer,
    Header,
    StarterWrapper,
} from "./styles/SelectTeam.styled";
import LimitInfo from "../molecules/LimitInfo";
import AnalyticsSelect from "../atoms/AnalyticsSelect";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import { TableSkeleton } from "../atoms/Skeletons";
import { VerticalPadding } from "../atoms";

interface SelectTeamProps {
    league: League;
    leagueUser: LeaguesLeagueUser;
    open: boolean;
    setOpen: (open: boolean) => void;
    show: Show;
    size: number;
    setTeam: (set: boolean) => void;
}

const SelectTeam: React.FC<SelectTeamProps> = ({
    size,
    open,
    setOpen,
    show,
    league,
    leagueUser,
    setTeam: setUpTeam,
}) => {
    const subscription = useSelector((state: RootState) => state.subscription);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [team, setTeam] = useState<Cast[] | []>([]);
    const [cast, setCast] = useState<Cast[]>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [sort, setSort] = useState<string>("name");

    const castRequest = useApi(apiRoutes.GET_CAST_BY_SHOW(show.id.toString()), {
        onSuccess: (response: Cast[]) => {
            setCast(response);
            setLoading(false);
        },
        onFailure: () => setLoading(false),
        responseKey: "cast",
    });
    const selectTeamRequest = useApi(
        apiRoutes.SELECT_TEAM_LINEUP(leagueUser.id.toString()),
        {
            onSuccess: (response: string) => {
                toast.success(response);
                closePanel();
                setSubmitting(false);
                setUpTeam(false);
            },
            onFailure: () => {
                setSubmitting(false);
            },
            responseKey: "message",
        }
    );

    const removePlayer = (id: number) => {
        const newCast = team.filter((cast: Cast) => cast.id !== id);
        setTeam(newCast);
    };

    const addPlayer = (cast: Cast) => {
        setTeam([...team, cast]);
    };

    const verifyLimits = (): boolean => {
        const males = team.filter((player: Cast) => {
            return player.gender === "M";
        });
        const females = team.filter((player: Cast) => {
            return player.gender === "F";
        });
        const rookies = team.filter((player: Cast) => {
            return player.rookie === true;
        });

        const maleLimit = league.male_limit ? league.male_limit : 0;
        const femaleLimit = league.female_limit ? league.female_limit : 0;
        const rookieLimit = league.rookie_limit ? league.rookie_limit : 0;

        if (
            maleLimit <= males.length &&
            femaleLimit <= females.length &&
            rookieLimit <= rookies.length
        ) {
            return true;
        } else {
            if (maleLimit > males.length) {
                toast.error("Please choose more males to fit limit!");
            }
            if (femaleLimit > females.length) {
                toast.error("Please choose more females to fit limit!");
            }
            if (rookieLimit > rookies.length) {
                toast.error("Please choose more rookies to fit limit!");
            }

            return false;
        }
    };

    const saveTeam = () => {
        if (team.length < league.players_per_team) {
            toast.error("Please select a full team!");
        } else {
            if (verifyLimits()) {
                setSubmitting(true);
                selectTeamRequest.request({ team });
            }
        }
    };

    const closePanel = () => {
        setOpen(false);
        document.body.style.overflow = "auto";
    };

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
            castRequest.request({ active: true, league_id: league.id });
            toast.warning(
                "You must select a team before participating in this league. You will not be able to change this team once selected! You will only be able to update your starters week to week"
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const hasLimits = () => {
        return league.female_limit || league.rookie_limit || league.male_limit;
    };

    return (
        <>
            {" "}
            <SlidingPanel
                type={"left"}
                isOpen={open}
                size={90}
                backdropClicked={closePanel}
            >
                <div className="overflow-hidden">
                    <Header style={isMobile ? { height: 200 } : {}}>
                        <h5>Your Team</h5>
                        {hasLimits() && (
                            <small>
                                Team Limits:{" "}
                                {league.rookie_limit &&
                                    `Rookie (${league.rookie_limit}), `}
                                {league.male_limit &&
                                    `Male (${league.male_limit}), `}
                                {league.female_limit &&
                                    `Female (${league.female_limit})`}{" "}
                                <InfoIcon
                                    fontSize="small"
                                    onClick={() => setShowModal(true)}
                                />
                            </small>
                        )}
                        <StarterWrapper>
                            {team.length > 0 &&
                                team.map((player: any) => {
                                    return (
                                        <WeeklyStarter
                                            player={player}
                                            key={player.id}
                                            removePlayer={removePlayer}
                                        />
                                    );
                                })}
                            {team.length < size &&
                                Array.from(
                                    Array(size - team.length),
                                    (e, i) => {
                                        return <StarterEmpty key={i} />;
                                    }
                                )}
                        </StarterWrapper>
                        {isMobile && (
                            <>
                                <VerticalPadding />
                                <Button
                                    disabled={submitting}
                                    variant="success"
                                    onClick={saveTeam}
                                >
                                    {submitting ? (
                                        <>
                                            Saving{" "}
                                            <CircularProgress
                                                size={12}
                                                color="inherit"
                                            />
                                        </>
                                    ) : (
                                        "Save Team"
                                    )}
                                </Button>{" "}
                                <Button variant="light" onClick={closePanel}>
                                    Cancel
                                </Button>
                            </>
                        )}
                    </Header>
                    <Body
                        style={
                            isMobile
                                ? {
                                      height: "calc(100% - 200px)",
                                      top: 200,
                                      paddingBottom: 100,
                                      borderBottomRightRadius: 20,
                                  }
                                : {}
                        }
                    >
                        {!loading ? (
                            <List>
                                {subscription &&
                                    hasPremiumSub(subscription.name) && (
                                        <div className="ml-3 mr-3">
                                            <AnalyticsSelect
                                                secondary="Owned (site wide)"
                                                tertiary="Owned (this league)"
                                                value={sort}
                                                onChange={(value: string) => {
                                                    setSort(value);
                                                    let newCast: Cast[] = [];
                                                    if (value === "secondary") {
                                                        newCast = cast
                                                            ? cast.sort(
                                                                  (a, b) =>
                                                                      parseFloat(
                                                                          a.team_owned ??
                                                                              "0"
                                                                      ) <
                                                                      parseFloat(
                                                                          b.team_owned ??
                                                                              "0"
                                                                      )
                                                                          ? 1
                                                                          : -1
                                                              )
                                                            : [];
                                                    } else if (
                                                        value === "tertiary"
                                                    ) {
                                                        newCast = cast
                                                            ? cast.sort(
                                                                  (a, b) =>
                                                                      parseFloat(
                                                                          a.team_owned_league ??
                                                                              "0"
                                                                      ) <
                                                                      parseFloat(
                                                                          b.team_owned_league ??
                                                                              "0"
                                                                      )
                                                                          ? 1
                                                                          : -1
                                                              )
                                                            : [];
                                                    } else {
                                                        newCast = cast
                                                            ? cast.sort(
                                                                  (a, b) =>
                                                                      a.first_name >
                                                                      b.first_name
                                                                          ? 1
                                                                          : -1
                                                              )
                                                            : [];
                                                    }
                                                    setCast([...newCast]);
                                                }}
                                            />
                                        </div>
                                    )}
                                <Divider />
                                {cast &&
                                    cast.map((player: Cast) => {
                                        return (
                                            <SelectTeamListItem
                                                key={player.id}
                                                player={player}
                                                startingCast={team}
                                                addPlayer={addPlayer}
                                                removePlayer={removePlayer}
                                                league={league}
                                            />
                                        );
                                    })}
                            </List>
                        ) : (
                            <>
                                <TableSkeleton />
                                <TableSkeleton />
                                <TableSkeleton />
                                <TableSkeleton />
                            </>
                        )}
                    </Body>
                    {!isMobile && (
                        <Footer>
                            <Button
                                disabled={submitting}
                                variant="success"
                                onClick={saveTeam}
                            >
                                {submitting ? (
                                    <>
                                        Saving{" "}
                                        <CircularProgress
                                            size={12}
                                            color="inherit"
                                        />
                                    </>
                                ) : (
                                    "Save Team"
                                )}
                            </Button>{" "}
                            <Button variant="light" onClick={closePanel}>
                                Cancel
                            </Button>
                        </Footer>
                    )}
                </div>
            </SlidingPanel>
            <LimitInfo
                maleLimit={league.male_limit}
                femaleLimit={league.female_limit}
                rookieLimit={league.rookie_limit}
                setShowModal={setShowModal}
                showModal={showModal}
            />
        </>
    );
};

export default SelectTeam;
