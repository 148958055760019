import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";

import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import DeleteIcon from "@mui/icons-material/Delete";

import { determineTimezone } from "../../helpers/timezones";
import { RootState } from "../../store";
import useApi from "../../hooks/useApi";
import {
    Leaderboard,
    LeaderboardList,
    LeaderboardPagination,
} from "../../models/Leaderboard";
import apiRoutes from "../../services/apiRoutes";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { League } from "../../models/Leagues";

import { Well } from "../atoms";
import Table from "../atoms/Table";
import { TableSkeleton } from "../atoms/Skeletons";
import { Show } from "../../models/Shows";
import EpisodeFilterSelect from "../atoms/EpisodeFilterSelect";
import AreYouSureModal from "../atoms/AreYouSureModal";
import ProfileOverlay from "../atoms/ProfileOverlay";
import ViewProfile from "../molecules/ViewProfile";
import { imageUrl } from "../../config";

interface OverviewProps {
    leagueId: string;
    league: League;
    leagueUser: LeaguesLeagueUser;
    show: Show;
    onDeleteTeam: () => void;
}

const Overview: React.FC<OverviewProps> = ({
    leagueId,
    league,
    leagueUser,
    show,
    onDeleteTeam,
}) => {
    const leaderboardRequest = useApi(
        apiRoutes.GET_LEADERBOARD_BY_LEAGUE(leagueId),
        {
            onSuccess,
        }
    );
    const leaderboardEpisodeRequest = useApi(
        apiRoutes.GET_LEADERBOARD_BY_LEAGUE(leagueId),
        {
            onSuccess: onEpisodeSuccess,
        }
    );
    const history = useHistory();
    const theme = useTheme();

    const pageSize = 200;
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );
    const [loaded, setLoaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [leaderboard, setLeaderboard] = useState<LeaderboardList | []>([]);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [episode, setEpisode] = useState<string>("0");
    const [selectedTeam, setSelectedTeam] = useState<Leaderboard>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [showProfile, setShowProfile] = useState<boolean>(false);
    const [profileId, setProfileId] = useState<number>(0);

    const deleteTeamRequest = useApi(
        apiRoutes.DELETE_TEAM(selectedTeam ? selectedTeam.id.toString() : "0"),
        {
            onSuccess: (response: string) => {
                toast.success(response);
                setDeleting(false);
                setShowDeleteModal(false);
                onDeleteTeam();
            },
            onFailure: () => {
                setDeleting(false);
            },
            responseKey: "message",
        }
    );

    useEffect(() => {
        const timezones = determineTimezone(timezoneOffsets);
        leaderboardRequest.request({
            limit: pageSize,
            show_id: show.id,
            ...timezones,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leagueId, show.id]);

    useEffect(() => {
        setPage(1);
        setLoaded(false);
        setLoading(true);
        setLeaderboard([]);
        const timezones = determineTimezone(timezoneOffsets);
        leaderboardEpisodeRequest.request({
            show_id: show.id,
            episode: episode,
            page: 1,
            limit: pageSize,
            ...timezones,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [episode]);

    function onSuccess(response: LeaderboardPagination) {
        setLeaderboard([...leaderboard, ...response.leaderboard]);
        setHasMore(response.has_more);
        setPage(parseInt(response.page.toString()) + 1);
        setLoaded(true);
        setLoading(false);
    }

    function onEpisodeSuccess(response: LeaderboardPagination) {
        setLeaderboard(response.leaderboard);
        setHasMore(response.has_more);
        setPage(parseInt(response.page.toString()) + 1);
        setLoaded(true);
        setLoading(false);
    }

    const fetchNextOverviewData = () => {
        const timezones = determineTimezone(timezoneOffsets);
        if (episode !== "0") {
            leaderboardRequest.request({
                page: page,
                limit: pageSize,
                show_id: show.id,
                episode: episode,
                ...timezones,
            });
        } else {
            leaderboardRequest.request({
                page: page,
                limit: pageSize,
                show_id: show.id,
                ...timezones,
            });
        }
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEpisode(event.target.value);
    };

    const deleteTeam = () => {
        setDeleting(true);
        deleteTeamRequest.request();
    };

    return (
        <Well id="scrollableDivOverview">
            <EpisodeFilterSelect
                onChange={onChange}
                episode={parseFloat(episode)}
                show={show}
            />
            {loading ? (
                <>
                    <TableSkeleton />
                    <TableSkeleton />
                </>
            ) : (
                <InfiniteScroll
                    dataLength={leaderboard.length}
                    hasMore={hasMore}
                    next={() => fetchNextOverviewData()}
                    loader={leaderboard.length > 0 ? <TableSkeleton /> : <></>}
                >
                    <Table>
                        <thead>
                            <tr className="header">
                                <th>Rank</th>
                                <th>Team Name</th>
                                <th className="text-right pr-5">Points</th>
                                {leagueUser &&
                                    leagueUser.commissioner &&
                                    league.active && <th></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {episode !== "0" &&
                                leaderboard.length <= 0 &&
                                loaded && (
                                    <tr
                                        style={{ color: theme.textColor }}
                                        className="mt-4 text-center"
                                    >
                                        <td
                                            colSpan={
                                                leagueUser &&
                                                leagueUser.commissioner &&
                                                league.active
                                                    ? 4
                                                    : 3
                                            }
                                        >
                                            No Scores for{" "}
                                            {show.type === "week"
                                                ? "Week"
                                                : "Episode"}{" "}
                                            {episode}
                                        </td>
                                    </tr>
                                )}
                            {leaderboard.length > 0 &&
                                loaded &&
                                leaderboard.map(
                                    (team: Leaderboard, key: number) => {
                                        //used for live scoring to determine correct rank
                                        if (
                                            team.rank === undefined &&
                                            episode === "0"
                                        ) {
                                            const morePoints =
                                                leaderboard.filter(
                                                    (lead: Leaderboard) => {
                                                        return (
                                                            lead.points >
                                                            team.points
                                                        );
                                                    }
                                                );
                                            team.rank = morePoints.length + 1;
                                        }

                                        return (
                                            <tr
                                                onClick={() =>
                                                    history.push(
                                                        `/myleagues/team/${team.id}`
                                                    )
                                                }
                                                key={team.id}
                                                className={
                                                    leagueUser &&
                                                    team.id.toString() ===
                                                        leagueUser.id.toString()
                                                        ? ` selected`
                                                        : ``
                                                }
                                            >
                                                <td>
                                                    {team.rank
                                                        ? team.rank
                                                        : key + 1}
                                                </td>
                                                <td>
                                                    <div className="d-flex flex-column flex-md-row align-items-md-center">
                                                        <ProfileOverlay
                                                            img={
                                                                team.profile_pic &&
                                                                team.profile_pic.includes(
                                                                    "https"
                                                                )
                                                                    ? team.profile_pic
                                                                    : `${imageUrl}${team.profile_pic}`
                                                            }
                                                            showProfile={(
                                                                visible: boolean
                                                            ) => {
                                                                setShowProfile(
                                                                    visible
                                                                );
                                                                setProfileId(
                                                                    team.user_id
                                                                );
                                                            }}
                                                        />
                                                        <div>
                                                            <div>
                                                                <Link
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        e.stopPropagation()
                                                                    }
                                                                    to={`/myleagues/team/${team.id}`}
                                                                >
                                                                    {
                                                                        team.team_name
                                                                    }
                                                                </Link>
                                                            </div>
                                                            <div>
                                                                {
                                                                    team.first_name
                                                                }{" "}
                                                                {team.last_name &&
                                                                team.last_name
                                                                    .length > 0
                                                                    ? team.last_name.substring(
                                                                          0,
                                                                          1
                                                                      )
                                                                    : ""}
                                                                {team.commissioner ? (
                                                                    <LocalPoliceIcon
                                                                        color="primary"
                                                                        className="ml-1"
                                                                    />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-right pr-5">
                                                    {Number(
                                                        team.points
                                                    ).toFixed(0)}
                                                </td>
                                                {leagueUser &&
                                                    leagueUser.commissioner &&
                                                    league.active && (
                                                        <td className="text-right pr-5">
                                                            <DeleteIcon
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    setSelectedTeam(
                                                                        team
                                                                    );
                                                                    setShowDeleteModal(
                                                                        true
                                                                    );
                                                                }}
                                                            />
                                                        </td>
                                                    )}
                                            </tr>
                                        );
                                    }
                                )}
                            {leaderboard.length === 0 &&
                                loaded &&
                                episode === "0" && (
                                    <tr>
                                        <td className="text-center" colSpan={3}>
                                            There are no players currently in
                                            this league. Be the first one!
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </Table>
                </InfiniteScroll>
            )}
            <AreYouSureModal
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                body={`Are you sure you want to remove <b>${
                    selectedTeam && selectedTeam.team_name
                }</b> from your league?`}
                onSuccess={() => deleteTeam()}
                submitting={deleting}
                submitText={"Removing"}
            />
            <ViewProfile
                showModal={showProfile}
                setShowModal={setShowProfile}
                userId={profileId}
            />
        </Well>
    );
};

export default Overview;
