import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import { TableSkeleton } from "../atoms/Skeletons";
import LinkList from "../molecules/LinkList";
import PageTemplate from "../templates/PageTemplate";

interface List {
    title: string;
    link: string;
    custom: boolean;
}

interface ShowList {
    other: List[];
    active: List[];
}

const AdminShowsList: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [list, setList] = useState<ShowList>();
    const getShowsList = useApi(apiRoutes.GET_SHOWS_LIST(), {
        responseKey: "list",
        onSuccess: (response: ShowList) => {
            setList(response);
            setLoading(false);
        },
        onFailure: () => setLoading(false),
    });

    useEffect(() => {
        getShowsList.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageTemplate
            header="Manage Shows"
            loading={loading}
            skeleton={
                <>
                    <TableSkeleton />
                    <TableSkeleton />
                </>
            }
        >
            {list && (
                <div className="mb-4">
                    <h4>Active Shows:</h4>
                    {list.active.length > 0 ? (
                        <LinkList list={list.active} />
                    ) : (
                        <span>No Active shows</span>
                    )}
                </div>
            )}
            {list && (
                <div className="mb-4">
                    <h4>Other Shows:</h4>
                    {list.other.length > 0 ? (
                        <LinkList list={list.other} />
                    ) : (
                        <span>No Active shows</span>
                    )}
                </div>
            )}
            <Button href={`/admin/shows/add`}>Add New Show</Button>
        </PageTemplate>
    );
};

export default AdminShowsList;
