export const validGenderLimits = (
    male: null | number,
    female: null | number,
    total: number
) => {
    const maleLimit = male !== null && male !== undefined ? male : 0;
    const femaleLimit = female !== null && male !== undefined ? female : 0;

    if (
        parseInt(maleLimit.toString()) + parseInt(femaleLimit.toString()) <=
        parseInt(total.toString())
    ) {
        return true;
    } else {
        return false;
    }
};

export const validRookieLimit = (rookie: null | number, total: number) => {
    const rookieLimit = rookie !== null && rookie !== undefined ? rookie : 0;

    if (parseInt(rookieLimit.toString()) <= total) {
        return true;
    } else {
        return false;
    }
};
