import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import { FaUserEdit, FaClipboardList } from "react-icons/fa";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import HelpIcon from "@mui/icons-material/Help";
import GroupsIcon from "@mui/icons-material/Groups";
import { TbSwitch2 } from "react-icons/tb";
import { MdOutlinePostAdd } from "react-icons/md";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRankingStar } from "@fortawesome/free-solid-svg-icons";
import { IoAnalyticsSharp } from "react-icons/io5";
import { IoMdPersonAdd } from "react-icons/io";

import { League } from "../../models/Leagues";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { Show } from "../../models/Shows";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import useQuery from "../../hooks/useQuery";
import { RootState } from "../../store";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";

import InviteFriends from "../molecules/InviteFriends";
import ProposeTrade from "./ProposeTrade";
import TradeList from "./TradeList";
import SetTeam from "./SetTeam";
import SelectTeam from "./SelectTeam";
import SetWeeklyLineup from "./SetWeeklyLineup";
import SetWeeklyBudgetLineup from "./SetWeeklyBudgetLineup";
import SwapPlayerModal from "./SwapPlayer";
import LeagueActionItem from "../atoms/LeagueActionItem";
import AnswerChallengeQuestions from "./AnswerChallengeQuestions";
import SetConfidencePool from "./SetConfidencePool";

interface LeagueActionButtonsProps {
    league: League;
    leagueUser: LeaguesLeagueUser;
    show: Show;
    tradeCount: number;
    teamLeagueSetUp: boolean;
    showSelectTeam: boolean;
    reloadLeague: () => void;
}

const LeagueActionButtons: React.FC<LeagueActionButtonsProps> = ({
    league,
    leagueUser,
    show,
    tradeCount,
    teamLeagueSetUp,
    showSelectTeam,
    reloadLeague,
}) => {
    const history = useHistory();
    const query = useQuery();
    const type = query.get("type") ? query.get("type") : "";
    const subscription = useSelector((state: RootState) => state.subscription);
    const [showInviteFriends, setShowInviteFriends] = useState<boolean>(false);
    const [tradeOpen, setTradeOpen] = useState<boolean>(
        type === "trades" ? true : false
    );
    const [proposeTradeOpen, setProposeTradeOpen] = useState<boolean>(false);
    const [challengeOpen, setChallengeOpen] = useState<boolean>(false);
    const [teamOpen, setTeamOpen] = useState<boolean>(
        type === "teamLineup" ? true : false
    );
    const [weeklyOpen, setWeeklyOpen] = useState<boolean>(
        type === "weeklyLineup" ? true : false
    );
    const [weeklyBudgetOpen, setWeeklyBudgetOpen] = useState<boolean>(
        type === "budgetLineup" ? true : false
    );
    const [confidencePoolOpen, setConfidencePoolOpen] = useState<boolean>(
        type === "confidenceLineup" ? true : false
    );
    const [selectTeam, setSelectTeam] = useState<boolean>(showSelectTeam);
    const [teamSetUp, setTeamSetUp] = useState<boolean>(teamLeagueSetUp);
    const [swapOpen, setSwapOpen] = useState<boolean>(false);
    const [setLineup, setSetLineup] = useState<boolean>(false);
    const [answerQuestions, setAnswerQuestions] = useState<boolean>(false);

    const checkSetLineup = useApi(
        apiRoutes.CHECK_SET_LINEUP(leagueUser.id.toString()),
        {
            onSuccess: (response: {
                setLineup: boolean;
                answerQuestions: boolean;
            }) => {
                setSetLineup(response.setLineup);
                setAnswerQuestions(response.answerQuestions);
            },
        }
    );

    useEffect(() => {
        if (
            league.draft_type === "Weekly Budget" ||
            league.draft_type === "Weekly Redraft" ||
            league.draft_type === "Team" ||
            league.draft_type === "Confidence Pool" ||
            league.draft_type === "Eliminated Confidence Pool"
        ) {
            checkSetLineup.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {(league.draft_type === "Confidence Pool" ||
                league.draft_type === "Eliminated Confidence Pool") && (
                <LeagueActionItem
                    icon={
                        <FontAwesomeIcon fontSize={20} icon={faRankingStar} />
                    }
                    label="Make Picks"
                    onPress={() => setConfidencePoolOpen(true)}
                    hideBadge={!setLineup}
                />
            )}
            {league.draft_type === "Weekly Redraft" && (
                <LeagueActionItem
                    icon={<GroupAddIcon />}
                    label="Set Lineup"
                    onPress={() => setWeeklyOpen(true)}
                    hideBadge={!setLineup}
                />
            )}
            {league.draft_type === "Weekly Budget" && (
                <LeagueActionItem
                    icon={<GroupAddIcon />}
                    label="Set Lineup"
                    onPress={() => setWeeklyBudgetOpen(true)}
                    hideBadge={!setLineup}
                />
            )}
            {league.draft_type === "Team" && teamSetUp && (
                <LeagueActionItem
                    icon={<GroupsIcon />}
                    label="Select Team"
                    onPress={() => setSelectTeam(true)}
                    hideBadge={false}
                />
            )}
            {league.draft_type === "Team" &&
                show.current_episode <= 1 &&
                !show.locked && (
                    <LeagueActionItem
                        icon={<GroupsIcon />}
                        label="Re-Select Team"
                        onPress={() => setSelectTeam(true)}
                    />
                )}
            {league.draft_type === "Team" && !teamSetUp && (
                <LeagueActionItem
                    icon={<GroupAddIcon />}
                    label="Set Lineup"
                    onPress={() => setTeamOpen(true)}
                    hideBadge={!setLineup}
                />
            )}
            {league.include_questions &&
                show.questions &&
                show.questions.length > 0 && (
                    <LeagueActionItem
                        icon={<HelpIcon />}
                        label="Questions"
                        onPress={() => setChallengeOpen(true)}
                        hideBadge={!answerQuestions}
                    />
                )}
            {league.draft_type === "Team" && league.swaps && !teamSetUp && (
                <LeagueActionItem
                    icon={<TbSwitch2 size={20} />}
                    label="Swap Player"
                    onPress={() => setSwapOpen(true)}
                />
            )}
            {league.draft_type === "Full Draft" &&
                !league.drafted &&
                league.active && (
                    <LeagueActionItem
                        icon={<MdOutlinePostAdd size={20} />}
                        label="Join Draft"
                        onPress={() =>
                            history.push(`/myleagues/draft/${league.id}`, {
                                league: league,
                            })
                        }
                        hideBadge={league.draft_started ? false : true}
                    />
                )}
            {tradeCount > 0 && (
                <LeagueActionItem
                    icon={<SwapHorizIcon />}
                    label={`Trades (${tradeCount})`}
                    onPress={() => setTradeOpen(true)}
                    hideBadge={tradeCount > 0 ? false : true}
                />
            )}
            {league.draft_type === "Full Draft" && (
                <LeagueActionItem
                    icon={<SwapHorizIcon />}
                    label="Propose Trade"
                    onPress={() => setProposeTradeOpen(true)}
                />
            )}
            <LeagueActionItem
                icon={<FaUserEdit size={20} />}
                label="Edit Team"
                onPress={() =>
                    history.push(
                        `/myleagues/edit/team/${leagueUser.id}/${league.show_id}`
                    )
                }
            />
            {subscription && hasPremiumSub(subscription.name) && (
                <LeagueActionItem
                    icon={<IoAnalyticsSharp size={20} />}
                    label="Analytics"
                    onPress={() => {
                        if (
                            league.id !== "0" &&
                            league.id !== "team" &&
                            league.id !== 0 &&
                            league.id !== "budget"
                        ) {
                            history.push(`/myleagues/analytics/${league.id}`);
                        } else {
                            history.push(
                                `/myleagues/analytics/${league.id}/${league.show_id}`
                            );
                        }
                    }}
                />
            )}
            {league.draft_type === "Full Draft" && league.drafted && (
                <LeagueActionItem
                    icon={<FaClipboardList size={20} />}
                    label="Draft Recap"
                    onPress={() =>
                        history.push(`/myleagues/draftRecap/${league.id}`)
                    }
                />
            )}
            {league.id !== "0" &&
                league.id !== "team" &&
                league.id !== 0 &&
                league.id !== "budget" && (
                    <LeagueActionItem
                        icon={<IoMdPersonAdd size={20} />}
                        label="Invite Friends"
                        onPress={() => setShowInviteFriends(true)}
                    />
                )}
            <InviteFriends
                leagueCode={league.league_code}
                leagueName={league.name}
                setShowModal={setShowInviteFriends}
                showModal={showInviteFriends}
            />
            <TradeList
                open={tradeOpen}
                setOpen={setTradeOpen}
                leagueUser={leagueUser}
                show={show}
                reload={reloadLeague}
            />
            <ProposeTrade
                open={proposeTradeOpen}
                setOpen={setProposeTradeOpen}
                leagueId={league.id}
                league={league}
                leagueUser={leagueUser}
                reload={reloadLeague}
                show={show}
            />
            <SelectTeam
                open={selectTeam}
                setOpen={setSelectTeam}
                size={league.players_per_team}
                show={show}
                league={league}
                leagueUser={leagueUser}
                setTeam={setTeamSetUp}
            />
            <SetTeam
                open={teamOpen}
                setOpen={setTeamOpen}
                leagueUser={leagueUser}
                locked={show.locked ?? false}
                show={show}
            />
            <SetWeeklyLineup
                open={weeklyOpen}
                setOpen={setWeeklyOpen}
                league={league}
                leagueUser={leagueUser}
                show={show}
            />
            <SetConfidencePool
                open={confidencePoolOpen}
                setOpen={setConfidencePoolOpen}
                league={league}
                leagueUser={leagueUser}
                show={show}
            />
            <AnswerChallengeQuestions
                open={challengeOpen}
                setOpen={setChallengeOpen}
                league={league}
                leagueUser={leagueUser}
                show={show}
            />
            <SetWeeklyBudgetLineup
                open={weeklyBudgetOpen}
                setOpen={setWeeklyBudgetOpen}
                league={league}
                leagueUser={leagueUser}
                show={show}
            />
            <SwapPlayerModal
                visible={swapOpen}
                setVisible={setSwapOpen}
                teamId={leagueUser.id}
                remaining={
                    league.swaps
                        ? league.swaps - (leagueUser.swaps_made + 1)
                        : 0
                }
            />
        </>
    );
};

export default LeagueActionButtons;
