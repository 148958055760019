import React from "react";
import { Helmet } from "react-helmet-async";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { MainContainer, PageHeader, Small, Spinner, Well } from "../atoms";
import {
    CardSkeleton,
    CommentSkeleton,
    InputSkeleton,
    LabelSkeleton,
    PageHeaderSkeleton,
    ScoreCardSkeleton,
    TextAreaSkeleton,
} from "../atoms/Skeletons";
import { Show } from "../../models/Shows";
import { imageUrl } from "../../config";
// import Ad from "../atoms/Ad";
import GoogleAd from "../atoms/GoogleAd";

interface PageProps {
    header: string;
    show: Show;
    headerSecondary?: string;
    loading?: boolean;
    skeleton?: boolean;
    sideCards: React.ReactElement;
}

const ScoresTemplate: React.FC<PageProps> = ({
    header,
    headerSecondary,
    show,
    loading,
    skeleton,
    sideCards,
    children,
}) => {
    return (
        <MainContainer>
            {loading && skeleton && (
                <>
                    <PageHeaderSkeleton />
                    <Row>
                        <Col lg={8}>
                            <LabelSkeleton />
                            <InputSkeleton />
                            <LabelSkeleton />
                            <InputSkeleton />
                            <ScoreCardSkeleton />
                            <ScoreCardSkeleton />
                            <ScoreCardSkeleton />
                            <ScoreCardSkeleton />
                            <Well>
                                <TextAreaSkeleton />
                                <CommentSkeleton />
                            </Well>
                        </Col>
                        <Col lg={4}>
                            <CardSkeleton />
                            <CardSkeleton />
                            <CardSkeleton />
                        </Col>
                    </Row>
                </>
            )}
            {loading && !skeleton && <Spinner />}
            {!loading && (
                <>
                    {show && (
                        <Helmet prioritizeSeoTags>
                            <title>{`${header} - RealTVFantasy`}</title>
                            <meta
                                name="description"
                                content={`${header} Are Now Posted! Check them out!`}
                            />
                            <meta
                                property="og:url"
                                content={`https://realtvfantasy.com${window.location.pathname}`}
                            />
                            <meta
                                property="og:title"
                                content={`${header} - RealTVFantasy`}
                            />
                            <meta
                                property="og:description"
                                content={`${header} Are Now Posted! Check them out!`}
                            />
                            <meta
                                property="og:image"
                                content={`${imageUrl}${show.img}`}
                            />
                            <meta
                                property="twitter:card"
                                content="summary_large_image"
                            />
                            <meta
                                property="twitter:title"
                                content={`${header} - RealTVFantasy`}
                            />
                            <meta
                                property="twitter:description"
                                content={`${header} Are Now Posted! Check them out!`}
                            />
                            <meta
                                property="twitter:image"
                                content={`${imageUrl}${show.img}`}
                            />
                        </Helmet>
                    )}
                    <PageHeader>
                        <h1>
                            {header}{" "}
                            {headerSecondary ? (
                                <Small>{headerSecondary}</Small>
                            ) : (
                                ""
                            )}
                        </h1>
                    </PageHeader>
                    <Row>
                        <Col lg={8}>{children}</Col>
                        <Col lg={4}>{sideCards}</Col>
                    </Row>
                    {/* <Ad fixed /> */}
                    <GoogleAd fixed slot="7455698336" height={90} />
                </>
            )}
        </MainContainer>
    );
};

export default ScoresTemplate;
