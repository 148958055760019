import React from "react";
import { useTheme } from "styled-components";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { imageUrl } from "../../config";
import { Cast } from "../../models/Cast";

import { ErrorText, Strikethrough, Well } from "../atoms";
import { CastCard, CastImg } from "./styles/CastList.styled";
import SocialLinks from "../atoms/SocialLinks";

interface CastListProps {
    cast: Cast[];
}

const CastList: React.FC<CastListProps> = ({ cast }) => {
    const theme = useTheme();

    return (
        <Well>
            <Row>
                {cast.length === 0 && (
                    <Col className="text-center">
                        Cast list not revealed yet. This will be updated once
                        the cast is revealed
                    </Col>
                )}
                {cast.length > 0 &&
                    cast.map((player) => (
                        <Col key={player.id} xl={4}>
                            <CastCard className="mb-5 cast-card">
                                <Card.Body className="flex-column text-center d-flex align-items-center">
                                    <CastImg src={`${imageUrl}${player.pic}`} />
                                </Card.Body>
                                <Card.Footer className="text-center">
                                    <h5>
                                        {player.eliminated ? (
                                            <ErrorText>
                                                <Strikethrough>
                                                    {player.first_name}{" "}
                                                    {player.last_name}
                                                </Strikethrough>{" "}
                                                (Eliminated)
                                            </ErrorText>
                                        ) : (
                                            <>
                                                {player.first_name}{" "}
                                                {player.last_name}
                                            </>
                                        )}
                                    </h5>
                                    {player.socials && (
                                        <SocialLinks socials={player.socials} />
                                    )}
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="top"
                                        overlay={
                                            <Popover
                                                id="cast-description"
                                                className="text-center"
                                            >
                                                <Popover.Content
                                                    style={{
                                                        backgroundColor:
                                                            theme.secondary,
                                                        color: theme.textColor,
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: player.description,
                                                    }}
                                                />
                                            </Popover>
                                        }
                                    >
                                        <Button>Description</Button>
                                    </OverlayTrigger>
                                </Card.Footer>
                            </CastCard>
                        </Col>
                    ))}
            </Row>
        </Well>
    );
};

export default CastList;
