import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ChallengeQuestion } from "../../models/ChallengeQuestions";

interface QuestionListProps {
    questions: ChallengeQuestion[];
}

const QuestionList: React.FC<QuestionListProps> = ({ questions }) => {
    return (
        <>
            {questions.length > 0 && (
                <>
                    <h4 className="col-sm-12 pt-4">Challenge Questions:</h4>
                    {questions.map((question, key) => {
                        return (
                            <React.Fragment key={question.id}>
                                {question.score !== 0 && (
                                    <Col key={question.id} md={6}>
                                        <Row className="p-1 mr-1 ml-1">
                                            <Col xs={10} sm={10} md={10}>
                                                {question.description}:
                                            </Col>
                                            <Col
                                                className="text-right"
                                                xs={2}
                                                sm={2}
                                                md={2}
                                            >
                                                <b>{question.score}</b>
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                            </React.Fragment>
                        );
                    })}
                </>
            )}
        </>
    );
};

export default QuestionList;
