import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

import Button from "react-bootstrap/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "react-bootstrap/Alert";

import { RootState } from "../../store";
import { League } from "../../models/Leagues";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { FullShow, Show } from "../../models/Shows";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { Cast } from "../../models/Cast";
import { LeagueChallengeQuestions } from "../../models/ChallengeQuestions";
import determineCountdown from "../../helpers/determineCountdown";

import { VerticalPadding } from "../atoms";
import CountdownTimer from "../molecules/CountdownTimer";
import { Body, Footer, Header } from "./styles/AnswerChallengeQuestions.styled";
import { TableSkeleton } from "../atoms/Skeletons";
import AnswerQuestionList from "../molecules/AnswerQuestionList";
import AddQuestionPlayerModal from "../molecules/AddQuestionPlayerModal";

interface AnswerChallengeQuestionsProps {
    league: League;
    leagueUser: LeaguesLeagueUser;
    show: Show;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export interface CastAnswer extends Cast {
    question_id: number;
    cast_id: number;
}

const AnswerChallengeQuestions: React.FC<AnswerChallengeQuestionsProps> = ({
    open,
    setOpen,
    league,
    leagueUser,
    show,
}) => {
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );
    const [episode, setEpisode] = useState<number>(show.current_episode);
    const [answers, setAnswers] = useState<CastAnswer[]>([]);
    const [showAddPlayer, setShowAddPlayer] = useState<boolean>(false);
    const [questions, setQuestions] = useState<LeagueChallengeQuestions[]>([]);
    const [selectedQuestionId, setSelectedQuestionId] = useState<number>(0);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const questionRequest = useApi(
        apiRoutes.GET_LEAGUE_QUESTIONS(
            league.id.toString(),
            show.id.toString()
        ),
        {
            onSuccess(response: {
                questions: LeagueChallengeQuestions[];
                show: FullShow;
                answers: CastAnswer[];
            }) {
                const filteredQuestions = response.questions.filter((q) => {
                    return q.score > 0;
                });
                setAnswers(response.answers);
                setEpisode(response.show.current_episode);
                setQuestions(filteredQuestions);
                setLoading(false);
            },
            onFailure: () => {
                setLoading(false);
            },
        }
    );

    const saveAnswerRequest = useApi(
        apiRoutes.SAVE_ANSWERS(leagueUser.id.toString()),
        {
            responseKey: "message",
            onSuccess: (response: string) => {
                toast.success(response);
                setSubmitting(false);
                closePanel();
            },
            onFailure: () => setSubmitting(false),
        }
    );

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
            questionRequest.request({ league_user_id: leagueUser.id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, show, leagueUser]);

    const closePanel = () => {
        setOpen(false);
        document.body.style.overflow = "auto";
    };

    const saveAnswers = () => {
        if (answers.length > 0) {
            setSubmitting(true);
            saveAnswerRequest.request({
                episode,
                question_answers: answers,
            });
        } else {
            toast.error("Please answer questions");
        }
    };

    return (
        <SlidingPanel
            type={"left"}
            isOpen={open}
            size={90}
            backdropClicked={() => closePanel()}
        >
            <>
                <div className="overflow-hidden">
                    <Header style={isMobile ? { height: 110 } : {}}>
                        <h5>Answer Challenge Questions</h5>
                        {isMobile && (
                            <>
                                <VerticalPadding />
                                {questions.length > 0 && (
                                    <>
                                        <Button
                                            disabled={
                                                submitting ||
                                                show.locked ||
                                                show.current_episode >
                                                    show.episodes
                                            }
                                            variant="success"
                                            onClick={saveAnswers}
                                        >
                                            {submitting ? (
                                                <>
                                                    Saving{" "}
                                                    <CircularProgress
                                                        size={12}
                                                        color="inherit"
                                                    />
                                                </>
                                            ) : (
                                                "Save Answers"
                                            )}
                                        </Button>{" "}
                                    </>
                                )}
                                <Button variant="light" onClick={closePanel}>
                                    Cancel
                                </Button>
                            </>
                        )}
                    </Header>
                    <Body
                        style={
                            isMobile
                                ? {
                                      height: "calc(100% - 110px)",
                                      top: 110,
                                      paddingBottom: 20,
                                      borderBottomRightRadius: 20,
                                  }
                                : {}
                        }
                    >
                        {loading ? (
                            <>
                                <TableSkeleton />
                                <TableSkeleton />
                                <TableSkeleton />
                                <TableSkeleton />
                            </>
                        ) : (
                            <>
                                {show.locked && (
                                    <Alert
                                        className="mr-2 ml-2"
                                        variant="warning"
                                    >
                                        Answers Are Locked For The Week
                                    </Alert>
                                )}
                                {!show.locked &&
                                    show.active &&
                                    show.current_episode <= show.episodes && (
                                        <div className="ml-3 mr-3">
                                            <CountdownTimer
                                                targetDate={determineCountdown(
                                                    timezoneOffsets,
                                                    show
                                                )}
                                            />
                                        </div>
                                    )}
                                {show.current_episode > show.episodes && (
                                    <Alert
                                        className="mr-2 ml-2"
                                        variant="warning"
                                    >
                                        The league has been completed
                                    </Alert>
                                )}
                                {questions.length > 0 ? (
                                    <AnswerQuestionList
                                        league={league}
                                        answers={answers}
                                        questions={questions}
                                        show={show}
                                        onSelect={(
                                            question: LeagueChallengeQuestions
                                        ) => {
                                            setSelectedQuestionId(
                                                question.question_id
                                            );
                                            setShowAddPlayer(true);
                                        }}
                                    />
                                ) : (
                                    <div className="text-center m-5">
                                        This league does not have any challenge
                                        questions.
                                    </div>
                                )}
                            </>
                        )}
                    </Body>
                    {!isMobile && (
                        <Footer>
                            {questions.length > 0 && (
                                <>
                                    <Button
                                        disabled={
                                            submitting ||
                                            show.locked ||
                                            show.current_episode > show.episodes
                                        }
                                        variant="success"
                                        onClick={saveAnswers}
                                    >
                                        {submitting ? (
                                            <>
                                                Saving{" "}
                                                <CircularProgress
                                                    size={12}
                                                    color="inherit"
                                                />
                                            </>
                                        ) : (
                                            "Save Answers"
                                        )}
                                    </Button>{" "}
                                </>
                            )}
                            <Button variant="light" onClick={closePanel}>
                                Cancel
                            </Button>
                        </Footer>
                    )}
                </div>
                <AddQuestionPlayerModal
                    showId={show.id}
                    visible={showAddPlayer}
                    setVisible={setShowAddPlayer}
                    onSubmit={(cast: Cast) => {
                        if (selectedQuestionId > 0) {
                            const answersList = answers.filter((a) => {
                                return a.question_id !== selectedQuestionId;
                            });
                            setAnswers([
                                ...answersList,
                                {
                                    ...cast,
                                    cast_id: cast.id,
                                    question_id: selectedQuestionId,
                                },
                            ]);
                            setShowAddPlayer(false);
                        } else {
                            toast.error(
                                "There was an issue submitting answers"
                            );
                        }
                    }}
                />
            </>
        </SlidingPanel>
    );
};

export default AnswerChallengeQuestions;
