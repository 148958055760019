import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";

import Button from "react-bootstrap/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "react-bootstrap/Alert";

import StarterEmpty from "../atoms/StarterEmpty";
import WeeklyStarter from "../molecules/WeeklyStarter";
import { League } from "../../models/Leagues";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { Show } from "../../models/Shows";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { Cast } from "../../models/Cast";
import determineCountdown from "../../helpers/determineCountdown";

import WeeklyListItem from "../molecules/WeeklyListItem";
import { ListContainer } from "../atoms";
import CountdownTimer from "../molecules/CountdownTimer";
import {
    Body,
    Footer,
    Header,
    StarterWrapper,
} from "./styles/SetWeeklyBudgetLineup.styled";
import AnalyticsSelect from "../atoms/AnalyticsSelect";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import { TableSkeleton } from "../atoms/Skeletons";

interface SetWeeklyLineupProps {
    league: League;
    leagueUser: LeaguesLeagueUser;
    show: Show;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export interface WeeklyCast extends Cast {
    price: number;
    remaining: null | number;
    weekly_started: string;
    weekly_started_league: string;
    weekly_budget_started_league: string;
    weekly_budget_started: string;
    average?: number;
}

const SetWeeklyBudgetLineup: React.FC<SetWeeklyLineupProps> = ({
    open,
    setOpen,
    league,
    leagueUser,
    show,
}) => {
    const subscription = useSelector((state: RootState) => state.subscription);
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );
    const [episode, setEpisode] = useState<number>(show.current_episode);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [cast, setCast] = useState<WeeklyCast[]>([]);
    const [startingCast, setStartingCast] = useState<WeeklyCast[]>([]);
    const [sort, setSort] = useState<string>("cost");
    const [remainingBudget, setRemainingBudget] = useState<number>(100);

    const lineupRequest = useApi(
        apiRoutes.GET_WEEKLY_LINEUP(leagueUser.id.toString()),
        {
            onSuccess: (response: {
                lineup: WeeklyCast[];
                available: WeeklyCast[];
                show: Show;
            }) => {
                const total = response.lineup.reduce(
                    (a, v) => (a = a + v.price),
                    0
                );
                setRemainingBudget(remainingBudget - total);
                setCast(response.available);
                setStartingCast(response.lineup);
                setEpisode(show.current_episode);
                setLoading(false);
            },
            onFailure: () => {
                setLoading(false);
            },
        }
    );
    const saveRequest = useApi(
        apiRoutes.SAVE_WEEKLY_LINEUP(leagueUser.id.toString()),
        {
            responseKey: "message",
            onSuccess: (response: string) => {
                toast.success(response);
                setSubmitting(false);
                closePanel();
            },
            onFailure: () => setSubmitting(false),
        }
    );

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
            lineupRequest.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, show]);

    const closePanel = () => {
        setRemainingBudget(100);
        setOpen(false);
        document.body.style.overflow = "auto";
    };

    const removePlayer = (id: number) => {
        const newStartingCast = startingCast.filter(
            (cast: WeeklyCast) => cast.id !== id
        );
        const removedPlayer = startingCast.filter(
            (cast: WeeklyCast) => cast.id === id
        )[0];
        const newCast = cast.map((e, i) => {
            if (e.id === id) {
                const updateCast = { ...e };
                updateCast.remaining = updateCast.remaining
                    ? updateCast.remaining + 1
                    : 1;
                return updateCast;
            } else {
                return e;
            }
        });
        if (removedPlayer) {
            setRemainingBudget(remainingBudget + removedPlayer.price);
        }
        setCast(newCast);
        setStartingCast(newStartingCast);
    };

    const addPlayer = (player: WeeklyCast) => {
        const updateCast = { ...player };
        updateCast.remaining = updateCast.remaining
            ? updateCast.remaining - 1
            : 0;
        const newCast = cast.map((e, i) => {
            if (e.id === player.id) {
                return updateCast;
            } else {
                return e;
            }
        });
        setCast(newCast);
        setRemainingBudget(remainingBudget - player.price);
        setStartingCast([...startingCast, player]);
    };

    const saveLineup = () => {
        if (startingCast.length > 0) {
            setSubmitting(true);
            saveRequest.request({
                team: startingCast,
                episode,
            });
        } else {
            toast.error("Please select players");
        }
    };

    const playersPerTeam = (): number => {
        if (league.draft_type === "Weekly Redraft") {
            if (league.players_per_team >= cast.length) {
                if (cast.length === 1) {
                    return 1;
                } else {
                    return cast.length - 1;
                }
            }
        }

        return league.players_per_team;
    };

    return (
        <SlidingPanel
            type={"left"}
            isOpen={open}
            size={90}
            backdropClicked={() => closePanel()}
        >
            <div className="overflow-hidden">
                <Header style={isMobile ? { height: 255 } : {}}>
                    <h5>
                        Current Team{" "}
                        <small>
                            {show.type === "week" ? "Week" : "Episode"}{" "}
                            {episode}
                        </small>
                    </h5>
                    <h6>
                        ({startingCast.length} / {playersPerTeam()})
                    </h6>
                    <StarterWrapper>
                        {startingCast.length > 0 &&
                            startingCast.map((player: WeeklyCast) => {
                                return (
                                    <WeeklyStarter
                                        player={player}
                                        key={player.id}
                                        removePlayer={removePlayer}
                                        locked={show.locked}
                                    />
                                );
                            })}
                        {startingCast.length < playersPerTeam() &&
                            Array.from(
                                Array(playersPerTeam() - startingCast.length),
                                (e, i) => {
                                    return <StarterEmpty key={i} />;
                                }
                            )}
                    </StarterWrapper>
                    <h5 className="mt-2">
                        Remaining Budget:{" "}
                        <FontAwesomeIcon fontSize={20} icon={faDollarSign} />
                        {remainingBudget}
                    </h5>
                    {isMobile && (
                        <>
                            <Button
                                disabled={
                                    submitting ||
                                    show.locked ||
                                    show.current_episode > show.episodes
                                }
                                variant="success"
                                onClick={saveLineup}
                            >
                                {submitting ? (
                                    <>
                                        Saving{" "}
                                        <CircularProgress
                                            size={12}
                                            color="inherit"
                                        />
                                    </>
                                ) : (
                                    "Save Lineup"
                                )}
                            </Button>{" "}
                            <Button variant="light" onClick={closePanel}>
                                Cancel
                            </Button>
                        </>
                    )}
                </Header>
                <Body
                    style={
                        isMobile
                            ? {
                                  height: "calc(100% - 255px)",
                                  top: 255,
                                  paddingBottom: 100,
                                  borderBottomRightRadius: 20,
                              }
                            : {}
                    }
                >
                    {loading ? (
                        <>
                            <TableSkeleton />
                            <TableSkeleton />
                            <TableSkeleton />
                            <TableSkeleton />
                        </>
                    ) : (
                        <ListContainer>
                            {show.locked && (
                                <Alert className="mr-2 ml-2" variant="warning">
                                    Lineups Are Locked For The Week
                                </Alert>
                            )}
                            {!show.locked &&
                                show.active &&
                                show.current_episode <= show.episodes && (
                                    <div className="ml-3 mr-3">
                                        <CountdownTimer
                                            targetDate={determineCountdown(
                                                timezoneOffsets,
                                                show
                                            )}
                                        />
                                    </div>
                                )}
                            {show.current_episode > show.episodes && (
                                <Alert className="mr-2 ml-2" variant="warning">
                                    The league has been completed
                                </Alert>
                            )}
                            {subscription &&
                                hasPremiumSub(subscription.name) && (
                                    <div className="ml-3 mr-3">
                                        <AnalyticsSelect
                                            draftType={league.draft_type}
                                            secondary="Starting (this league)"
                                            tertiary="Starting (site wide)"
                                            fourth="Average Score"
                                            value={sort}
                                            onChange={(value: string) => {
                                                setSort(value);
                                                let newCast: WeeklyCast[] = [];
                                                if (value === "secondary") {
                                                    newCast = cast.sort(
                                                        (a, b) =>
                                                            parseFloat(
                                                                a.weekly_budget_started_league
                                                            ) <
                                                            parseFloat(
                                                                b.weekly_budget_started_league
                                                            )
                                                                ? 1
                                                                : -1
                                                    );
                                                } else if (
                                                    value === "tertiary"
                                                ) {
                                                    newCast = cast.sort(
                                                        (a, b) =>
                                                            parseFloat(
                                                                a.weekly_budget_started
                                                            ) <
                                                            parseFloat(
                                                                b.weekly_budget_started
                                                            )
                                                                ? 1
                                                                : -1
                                                    );
                                                } else if (value === "cost") {
                                                    newCast = cast.sort(
                                                        (a, b) =>
                                                            a.price < b.price
                                                                ? 1
                                                                : -1
                                                    );
                                                } else if (value === "fourth") {
                                                    newCast = cast.sort(
                                                        (a, b) =>
                                                            a.average &&
                                                            b.average
                                                                ? a.average <
                                                                  b.average
                                                                    ? 1
                                                                    : -1
                                                                : a.created_at <
                                                                  b.created_at
                                                                ? 1
                                                                : -1
                                                    );
                                                } else {
                                                    newCast = cast.sort(
                                                        (a, b) =>
                                                            a.first_name >
                                                            b.first_name
                                                                ? 1
                                                                : -1
                                                    );
                                                }
                                                setCast([...newCast]);
                                            }}
                                        />
                                    </div>
                                )}
                            <Divider />
                            {cast.length > 0 &&
                                cast.map((player: WeeklyCast) => {
                                    return (
                                        <WeeklyListItem
                                            budget
                                            key={player.id}
                                            player={player}
                                            league={league}
                                            cast={cast}
                                            addPlayer={addPlayer}
                                            removePlayer={removePlayer}
                                            startingCast={startingCast}
                                            show={show}
                                            remainingBudget={remainingBudget}
                                        />
                                    );
                                })}
                        </ListContainer>
                    )}
                </Body>
                {!isMobile && (
                    <Footer>
                        <Button
                            disabled={
                                submitting ||
                                show.locked ||
                                show.current_episode > show.episodes
                            }
                            variant="success"
                            onClick={saveLineup}
                        >
                            {submitting ? (
                                <>
                                    Saving{" "}
                                    <CircularProgress
                                        size={12}
                                        color="inherit"
                                    />
                                </>
                            ) : (
                                "Save Lineup"
                            )}
                        </Button>{" "}
                        <Button variant="light" onClick={closePanel}>
                            Cancel
                        </Button>
                    </Footer>
                )}
            </div>
        </SlidingPanel>
    );
};

export default SetWeeklyBudgetLineup;
