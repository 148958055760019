import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

import List from "@mui/material/List";
import CircularProgress from "@mui/material/CircularProgress";
import ListItemText from "@mui/material/ListItemText";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { TeamLeagueTeams } from "../../models/TeamLeagueTeams";
import { Show } from "../../models/Shows";

import TeamLineupListItem from "../molecules/TeamLineupListItem";
import { Body, Footer, Header, ListHeader } from "./styles/SetTeam.styled";
import CountdownTimer from "../molecules/CountdownTimer";
import determineCountdown from "../../helpers/determineCountdown";
import AnalyticsSelect from "../atoms/AnalyticsSelect";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import { TableSkeleton } from "../atoms/Skeletons";

interface SetTeamProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    leagueUser: LeaguesLeagueUser;
    locked?: boolean;
    show: Show;
}

const SetTeam: React.FC<SetTeamProps> = ({
    open,
    setOpen,
    leagueUser,
    locked,
    show,
}) => {
    const subscription = useSelector((state: RootState) => state.subscription);
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );
    const [episode, setEpisode] = useState<number>(show.current_episode);
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [starters, setStarters] = useState<TeamLeagueTeams[]>([]);
    const [bench, setBench] = useState<TeamLeagueTeams[]>([]);
    const [starterMovingId, setStarterMovingId] = useState<number>(0);
    const [benchMovingId, setBenchMovingId] = useState<number>(0);
    const [sort, setSort] = useState<string>("name");

    const lineupRequest = useApi(
        apiRoutes.GET_TEAM_LINEUP(leagueUser.id.toString()),
        {
            onSuccess: (response: {
                starters: TeamLeagueTeams[];
                bench: TeamLeagueTeams[];
                show: Show;
            }) => {
                setStarters(response.starters);
                setBench(response.bench);
                setEpisode(show.current_episode);
                setLoading(false);
            },
            onFailure: () => setLoading(false),
        }
    );
    const saveRequest = useApi(
        apiRoutes.SAVE_TEAM_LINEUP(leagueUser.id.toString()),
        {
            responseKey: "message",
            onSuccess: (response: string) => {
                toast.success(response);
                setSubmitting(false);
                closePanel();
            },
            onFailure: () => setSubmitting(false),
        }
    );

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
            lineupRequest.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, show]);

    const closePanel = () => {
        setOpen(false);
        document.body.style.overflow = "auto";
    };

    const swapStarter = (id: number) => {
        const benchArrary = [...bench];
        const starterArray = [...starters];

        const starterIndex = starters.findIndex((starter) => starter.id === id);
        const benchIndex = bench.findIndex(
            (bench) => bench.id === benchMovingId
        );
        const starter = { ...starters[starterIndex] };
        const benchPlayer = { ...bench[benchIndex] };

        benchArrary[benchIndex] = starter;
        starterArray[starterIndex] = benchPlayer;

        setStarters(starterArray);
        setBench(benchArrary);
        setStarterMovingId(0);
        setBenchMovingId(0);
    };

    const swapBench = (id: number) => {
        const benchArrary = [...bench];
        const starterArray = [...starters];

        const starterIndex = starters.findIndex(
            (starter) => starter.id === starterMovingId
        );
        const benchIndex = bench.findIndex((bench) => bench.id === id);
        const starter = { ...starters[starterIndex] };
        const benchPlayer = { ...bench[benchIndex] };

        benchArrary[benchIndex] = starter;
        starterArray[starterIndex] = benchPlayer;

        setStarters(starterArray);
        setBench(benchArrary);
        setStarterMovingId(0);
        setBenchMovingId(0);
    };

    const saveTeamLineup = () => {
        setSubmitting(true);
        saveRequest.request({ starters, bench });
    };

    return (
        <SlidingPanel
            type={"left"}
            isOpen={open}
            size={90}
            backdropClicked={closePanel}
        >
            <div className="overflow-hidden">
                <Header style={isMobile ? { height: 100 } : {}}>
                    <h5>
                        Set Lineup <small>{leagueUser.team_name}</small>{" "}
                        <small>
                            {show.type === "week" ? "Week" : "Episode"}{" "}
                            {episode}
                        </small>
                    </h5>
                    {isMobile && (
                        <>
                            <Button
                                disabled={
                                    submitting ||
                                    locked ||
                                    show.current_episode > show.episodes
                                }
                                variant="success"
                                onClick={() => saveTeamLineup()}
                            >
                                {submitting ? (
                                    <>
                                        Saving{" "}
                                        <CircularProgress
                                            size={12}
                                            color="inherit"
                                        />
                                    </>
                                ) : (
                                    "Save Team"
                                )}
                            </Button>{" "}
                            <Button variant="light" onClick={closePanel}>
                                Cancel
                            </Button>
                        </>
                    )}
                </Header>
                <Body
                    style={
                        isMobile
                            ? {
                                  height: "calc(100% - 100px)",
                                  top: 100,
                                  paddingBottom: 100,
                                  borderBottomRightRadius: 20,
                              }
                            : {}
                    }
                >
                    {!loading ? (
                        <List>
                            {locked && (
                                <Alert className="ml-2 mr-2" variant="warning">
                                    Teams Are Locked For The Week
                                </Alert>
                            )}
                            {show.current_episode > show.episodes && (
                                <Alert className="ml-2 mr-2" variant="warning">
                                    This league has been completed
                                </Alert>
                            )}
                            {!locked &&
                                show.active &&
                                show.current_episode <= show.episodes && (
                                    <div className="mr-3 ml-3">
                                        <CountdownTimer
                                            targetDate={determineCountdown(
                                                timezoneOffsets,
                                                show
                                            )}
                                        />
                                    </div>
                                )}
                            {subscription &&
                                hasPremiumSub(subscription.name) && (
                                    <div className="ml-3 mr-3">
                                        <AnalyticsSelect
                                            secondary="Starting (site wide)"
                                            tertiary="Starting (this league)"
                                            fourth="Average Score"
                                            value={sort}
                                            onChange={(value: string) => {
                                                setSort(value);
                                                let newStarted: TeamLeagueTeams[] =
                                                    [];
                                                let newBench: TeamLeagueTeams[] =
                                                    [];
                                                setStarterMovingId(0);
                                                setBenchMovingId(0);
                                                if (value === "secondary") {
                                                    newStarted = starters.sort(
                                                        (a, b) =>
                                                            parseFloat(
                                                                a.team_started
                                                            ) <
                                                            parseFloat(
                                                                b.team_started
                                                            )
                                                                ? 1
                                                                : -1
                                                    );
                                                    newBench = bench.sort(
                                                        (a, b) =>
                                                            parseFloat(
                                                                a.team_started
                                                            ) <
                                                            parseFloat(
                                                                b.team_started
                                                            )
                                                                ? 1
                                                                : -1
                                                    );
                                                } else if (
                                                    value === "tertiary"
                                                ) {
                                                    newStarted = starters.sort(
                                                        (a, b) =>
                                                            parseFloat(
                                                                a.team_started_league
                                                            ) <
                                                            parseFloat(
                                                                b.team_started_league
                                                            )
                                                                ? 1
                                                                : -1
                                                    );
                                                    newBench = bench.sort(
                                                        (a, b) =>
                                                            a.team_started_league <
                                                            b.team_started_league
                                                                ? 1
                                                                : -1
                                                    );
                                                } else if (value === "fourth") {
                                                    newStarted = starters.sort(
                                                        (a, b) => {
                                                            return a.average &&
                                                                b.average
                                                                ? a.average <
                                                                  b.average
                                                                    ? 1
                                                                    : -1
                                                                : a.created_at <
                                                                  b.created_at
                                                                ? 1
                                                                : -1;
                                                        }
                                                    );
                                                    newBench = bench.sort(
                                                        (a, b) =>
                                                            a.average &&
                                                            b.average
                                                                ? a.average <
                                                                  b.average
                                                                    ? 1
                                                                    : -1
                                                                : a.created_at <
                                                                  b.created_at
                                                                ? 1
                                                                : -1
                                                    );
                                                } else {
                                                    newStarted = starters.sort(
                                                        (a, b) =>
                                                            a.first_name >
                                                            b.first_name
                                                                ? 1
                                                                : -1
                                                    );
                                                    newBench = bench.sort(
                                                        (a, b) =>
                                                            a.first_name >
                                                            b.first_name
                                                                ? 1
                                                                : -1
                                                    );
                                                }
                                                setStarters([...newStarted]);
                                                setBench([...newBench]);
                                            }}
                                        />
                                    </div>
                                )}
                            <ListHeader divider>
                                <ListItemText primary="Starters" />
                            </ListHeader>
                            {starters.map((player: TeamLeagueTeams) => {
                                return (
                                    <TeamLineupListItem
                                        key={player.id}
                                        player={player}
                                        swap={swapStarter}
                                        setMovingId={setStarterMovingId}
                                        mainMovingId={benchMovingId}
                                        secondaryMovingId={starterMovingId}
                                        currentEpisode={episode}
                                    />
                                );
                            })}
                            <ListHeader divider>
                                <ListItemText primary="Bench" />
                            </ListHeader>
                            {bench.map((player: TeamLeagueTeams) => {
                                return (
                                    <TeamLineupListItem
                                        key={player.id}
                                        player={player}
                                        swap={swapBench}
                                        setMovingId={setBenchMovingId}
                                        mainMovingId={starterMovingId}
                                        secondaryMovingId={benchMovingId}
                                        currentEpisode={episode}
                                    />
                                );
                            })}
                        </List>
                    ) : (
                        <>
                            <TableSkeleton />
                            <TableSkeleton />
                            <TableSkeleton />
                            <TableSkeleton />
                        </>
                    )}
                </Body>
                {!isMobile && (
                    <Footer>
                        <Button
                            disabled={
                                submitting ||
                                locked ||
                                show.current_episode > show.episodes
                            }
                            variant="success"
                            onClick={() => saveTeamLineup()}
                        >
                            {submitting ? (
                                <>
                                    Saving{" "}
                                    <CircularProgress
                                        size={12}
                                        color="inherit"
                                    />
                                </>
                            ) : (
                                "Save Team"
                            )}
                        </Button>{" "}
                        <Button variant="light" onClick={closePanel}>
                            Cancel
                        </Button>
                    </Footer>
                )}
            </div>
        </SlidingPanel>
    );
};

export default SetTeam;
