import { ApiReturnType } from "./";

const customScoringRoutes = {
    ADD_CUSTOM_SCORING: (leagueId: string): ApiReturnType => {
        return {
            url: `customScoring/add/${leagueId}`,
            method: "post",
        };
    },
    GET_CUSTOM_LOGGED_SCORES: (leagueId: string): ApiReturnType => {
        return {
            url: `leagues/getLoggedCustomScores/${leagueId}`,
            method: "get",
        };
    },
    SAVE_CUSTOM_LOGGED_SCORES: (leagueId: string): ApiReturnType => {
        return {
            url: `leagues/saveLoggedCustomScores/${leagueId}`,
            method: "post",
        };
    },
    SAVE_CUSTOM_SCORING: (leagueId: string): ApiReturnType => {
        return {
            url: `customScoring/save/${leagueId}`,
            method: "post",
        };
    },
};

export default customScoringRoutes;
